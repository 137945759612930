import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { ListingPropertyParams, GetListingProperties } from "./api";
import { useEffect, useState } from "react";
import { TextInput } from "react-admin";
import { Box, Button, Input, TextField } from "@material-ui/core";

export default (props) => {
    const [searchParams, setSearchParams] = useState<ListingPropertyParams>({});
    const [listingPropertyParams, setListingPropertyParams] =
        useState<ListingPropertyParams>({});
    const { isLoading, data: listingProperties } = GetListingProperties(
        listingPropertyParams,
    );
    return (
        <Box>
            <Box alignItems="center">
                <TextField
                    disabled={isLoading}
                    variant="outlined"
                    label="Postcode"
                    placeholder="Ex: W1B 5AW"
                    value={searchParams.postcode}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setSearchParams({
                            ...searchParams,
                            postcode: event.target.value,
                        });
                    }}
                />
                <TextField
                    style={{
                        marginLeft: "10px",
                    }}
                    disabled={isLoading}
                    variant="outlined"
                    label="Radius (Km)"
                    type="number"
                    placeholder="Default 10"
                    value={searchParams.distance}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setSearchParams({
                            ...searchParams,
                            distance: Number(event.target.value),
                        });
                    }}
                />
                <Button
                    disabled={
                        isLoading ||
                        (searchParams.distance ===
                            listingPropertyParams.distance &&
                            searchParams.postcode ===
                                listingPropertyParams.postcode)
                    }
                    style={{
                        marginLeft: "10px",
                    }}
                    variant="contained"
                    onClick={() => {
                        setListingPropertyParams({ ...searchParams });
                    }}
                >
                    Search
                </Button>
            </Box>
            <Box marginTop={3}>
                <MapContainer
                    style={{ width: "100%", height: "93vh" }}
                    center={[51.505, -0.09]}
                    zoom={13}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {listingProperties &&
                        listingProperties?.map((listingProperty) => (
                            <Marker
                                position={[
                                    listingProperty.geom.coordinates[1],
                                    listingProperty.geom.coordinates[0],
                                ]}
                            >
                                <Popup>{listingProperty.id}</Popup>
                            </Marker>
                        ))}
                </MapContainer>
            </Box>
        </Box>
    );
};
