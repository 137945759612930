import { AgGridReact } from "ag-grid-react";
import { useCallback } from 'react';

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

/**
 * Proof AgGrid can work inside ReactAdmin
 * However, we need to implement our own state rather than using react admin's "data provider"
 */
export default (props) => {
    const onFirstDataRendered = useCallback((params) => {
        params.api.sizeColumnsToFit();
    }, []);
    const columnDefs = [
        {
            field: "full_name",
            headerName: "Name",
            width: 200,

            suppressSizeToFit: true
        },
        {
            valueGetter: (params) => params.data.is_application_owner
                ? "YES"
                : "NO",
            headerName: "Owner",
            width: 80,
            suppressSizeToFit: true
        },
        {
            field: "age",
            headerName: "Age",
            width: 80,
            suppressSizeToFit: true
        },
        {
            valueGetter: (params) => (!!params.data.income.base_salary || params?.data.income.base_salary === 0) && params.data.income.base_salary.toLocaleString() || "NA",
            headerName: "Base Salary",
        },
        {
            valueGetter: (params) => (!!params.data.income.commission || params?.data.income.commission === 0) && params.data.income.commission.toLocaleString() || "NA",
            headerName: "Commission",
        },
        {
            valueGetter: (params) => (!!params.data.income.bonus_income || params?.data.income.bonus_income === 0) && params.data.income.bonus_income.toLocaleString() || "NA",
            headerName: "Bonus Income",
        },
        {
            valueGetter: (params) => (!!params.data.income.overtime_income || params?.data.income.overtime_income === 0) && params.data.income.overtime_income.toLocaleString() || "NA",
            headerName: "Overtime Income",
        },
        {
            valueGetter: (params) => (!!params.data.income.other_income || params?.data.income.other_income === 0) && params.data.income.other_income.toLocaleString() || "NA",
            headerName: "Other Income",
        },
        {
            valueGetter: (params) => (!!params.data.income.car_finance || params?.data.income.car_finance === 0) && params.data.income.car_finance.toLocaleString() || "NA",
            headerName: "Car Finance",
        },
        {
            valueGetter: (params) => (!!params.data.income.childcare_expenses || params?.data.income.childcare_expenses === 0) && params.data.income.childcare_expenses.toLocaleString() || "NA",
            headerName: "Childcare expenses",
        },
        {
            valueGetter: (params) => (!!params.data.income.credit_cards_loans || params?.data.income.credit_cards_loans === 0) && params.data.income.credit_cards_loans.toLocaleString() || "NA",
            headerName: "Credit card loans",
        },
        {
            valueGetter: (params) => (!!params.data.income.personal_loans || params?.data.income.personal_loans === 0) && params.data.income.personal_loans.toLocaleString() || "NA",
            headerName: "Personal loans",
        },
        {
            valueGetter: (params) => (!!params.data.income.student_loans || params?.data.income.student_loans === 0) && params.data.income.student_loans.toLocaleString() || "NA",
            headerName: "Student loans",
        },
        {
            valueGetter: (params) => (!!params.data.income.vehicle_loans || params.data.income.vehicle_loans === 0) && params.data.income.vehicle_loans.toString() || "NA",
            headerName: "Vehicle loans",
        },
    ];

    return (
        <div
            className="ag-theme-alpine"
            style={{ height: "200px", width: "100%", padding: "10px 0" }}
        >
            <AgGridReact
                rowData={props.data}
                columnDefs={columnDefs}
                onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
        </div>
    );
};
