
import { SettingsStore } from "../../store/settings";
import { update_settings } from "../../api-requests/settings-update";

import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import {
    Box,
    TextField,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Button
} from "@material-ui/core";



export default () => {
    const [settings, setSettings] = useState({} as any);

    const defaultSetting = {
        minimum_yield: 0,
        converted_payment: 0,
        fixed_costs: 0,
    };

    let _SettingsStore = SettingsStore();
    useEffect(() => {
        if (_SettingsStore.settings)
            _SettingsStore
                .fetchSettings()
                .then((res) => setSettings(_SettingsStore.settings));
    }, []);
    async function submitSettings() {

        try {
            if (
                window.confirm(
                    `Do you want to save calculator settings?`,
                )
            ) {
                await update_settings(settings);
                return;
            }


        } catch (err: any) {
            let message: string = `${err.message}\n`;

            let errors = (err as AxiosError).response?.data?.context?.errors;

            if (errors) {
                message = message.concat((errors as string[]).join("\n"));
                return alert(message);
            }
        }
    }

    function handleFormChange(event) {
        let { name, value } = event.target;

        setSettings({
            ...settings,
            [name]: Number(value),
        });
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            sx={{}}
        >
            <Card className="p-2">
                <CardHeader title="Calculator settings"></CardHeader>
                <CardContent>
                    <TextField
                        fullWidth
                        label="Minimum Yield"
                        name="minimum_yield"
                        value={Number(settings.minimum_yield) || defaultSetting.minimum_yield}
                        onChange={handleFormChange}
                        type="number"
                    />
                    <TextField
                        fullWidth
                        label="Converted Payment"
                        name="converted_payment"
                        value={Number(settings.converted_payment) || defaultSetting.converted_payment}
                        onChange={handleFormChange}
                        type="number"
                    />
                    <TextField
                        fullWidth
                        label="Fixed Costs"
                        name="fixed_costs"
                        value={Number(settings.fixed_costs) || defaultSetting.fixed_costs}
                        onChange={handleFormChange}
                        type="number"
                    />
                </CardContent>
                <CardActions>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={submitSettings}>
                        Save settings
                    </Button>
                </CardActions>
            </Card>
        </Box>
    );
};
