import ManageOffer from "./manage-offer";
import {
    Show,
    useShowController,
    TabbedShowLayout,
    Tab,
    TextField,
    ArrayField,
    DateField,
    Datagrid,
    BooleanField,
    ReferenceField,
    NumberField,
    TopToolbar,
    EditButton,
    List,
    useRefresh,
    FunctionField,
} from "react-admin";
import ManageBudget from "./manage-budget";
import PreviewBipPdf from "./preview-bip-pdf";
import SendBipReadyEmail from "./send-bip-ready-email";
import AppBudget from "./application-budget";
import DownloadApplicationExcel from "./download-underwriting-excel";
import SendApplicationApprovedEmail from "./send-application-approved-email";
import SendOfferReadyEmail from "./send-offer-ready-email";
import { Fragment, useState } from "react";
import { DocumentCreate } from "../../documents/create-document";
import { Modal, Button, Box } from "@material-ui/core";
import { BulkActionAppUnLinkDocumentsButton } from "./documents/bulk-action-link-button";
import { downloadDocument } from "../../documents/utils/download-document";

function _calculateAge(birthday) {
    // birthday is a date
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

const ApplicationActions = (props) => {
    let { record } = useShowController(props);

    const aplicationIncompleteStatuses = [
        "DRAFT",
        "INCOMPLETE",
        "NOT_ELIGIBLE",
        "AWAITING_UNDERWRITING",
        "FINDING_PROPERTY",
    ];
    return (
        <TopToolbar>
            <EditButton record={record}></EditButton>
            <ManageBudget record={record} />
            {!aplicationIncompleteStatuses.includes(record?.status) && (
                <ManageOffer record={record} />
            )}
            <PreviewBipPdf record={record} />
            <SendBipReadyEmail record={record} />
            <SendApplicationApprovedEmail record={record} />
            <SendOfferReadyEmail record={record} />
            <DownloadApplicationExcel record={record} />
        </TopToolbar>
    );
};

export default (props) => {
    const { record } = useShowController(props);
    const refresh = useRefresh();
    const [open, toggleModal] = useState(false);

    let budgetData =
        record?.applicants?.map?.((applicant) => {
            let summaryIncome = applicant.incomes.find(
                (record) => record.source_type === "SUMMARY",
            );

            return {
                id: applicant.id,
                full_name: applicant.full_name,
                is_application_owner: applicant.is_application_owner,
                age: _calculateAge(new Date(applicant.date_of_birth)),
                income: {
                    base_salary: summaryIncome?.base_salary,
                    commission: summaryIncome?.commission,
                    bonus_income: summaryIncome?.bonus_income,
                    other_income: summaryIncome?.other_income,
                    overtime_income: summaryIncome?.overtime_income,
                },
                expenses: {
                    car_finance: applicant.car_finance,
                    childcare_expenses: applicant.childcare_expenses,
                    credit_cards_loans: applicant.credit_cards_loans,
                    personal_loans: applicant.personal_loans,
                    student_loans: applicant.student_loans,
                    vehicle_loans: applicant.vehicle_loans,
                },
            };
        }) ?? [];
    return (
        <Show {...props} actions={<ApplicationActions {...props} />}>
            <TabbedShowLayout>
                <Tab label="Basic">
                    <TextField source="id"></TextField>
                    <TextField source="status"></TextField>
                    <TextField source="preferred_location"></TextField>
                    <NumberField
                        label="Calculated Budged In Principle"
                        source="property_budget"
                    />
                    <TextField source="notes"></TextField>
                    <BooleanField source="only_keyzy_now" />
                </Tab>

                <Tab label="applicants">
                    <ArrayField source="applicants" fieldKey="id">
                        <Datagrid>
                            <ReferenceField
                                reference="applicants"
                                label="Applicant ID"
                                source="id"
                                link="show"
                            >
                                <TextField source="id" />
                            </ReferenceField>
                            <TextField source="is_application_owner" />
                            <DateField source="created_at" showTime />
                            <DateField source="updated_at" showTime />
                            <TextField source="formal_title" />
                            <TextField source="first_name" />
                            <TextField source="last_name" />
                            {/* <TextField source="full_name" /> */}
                            <DateField
                                source="date_of_birth"
                                showTime
                                locales={"en-GB"}
                                options={{ timeZone: "UTC" }}
                            />
                            <TextField source="mobile_number" />
                            <TextField source="email" />
                            <TextField source="relationship_with_main_applicant" />
                            <TextField source="personal_details_progress" />
                            <TextField source="qualifying_criteria_progress" />
                            <TextField source="financial_details_progress" />
                            <TextField source="expenditure_details_progress" />
                            <TextField source="identity_verification_progress" />
                            <TextField source="income_verification_progress" />
                        </Datagrid>
                    </ArrayField>
                </Tab>

                <Tab label="Property">
                    <ArrayField source="properties" fieldKey="id">
                        <Datagrid>
                            <ReferenceField
                                reference="properties"
                                label="Property ID"
                                source="id"
                                link="show"
                            >
                                <TextField source="id" />
                            </ReferenceField>
                            <TextField source="address" />
                            <TextField source="property_viewed_by_customer" />
                            <TextField source="status" />
                            <TextField source="active" />
                            <TextField source="asking_price" />
                            <TextField source="offer_amount" />
                            <TextField source="offer_made" />
                            <TextField source="building_type" />
                            <TextField source="property_type" />
                        </Datagrid>
                    </ArrayField>
                </Tab>

                <Tab label="Budget in Principle">
                    <TextField label="ID" source="budget_in_principle.id" />
                    <DateField
                        label="Created At"
                        source="budget_in_principle.created_at"
                        showTime
                    />
                    <DateField
                        label="Updated At"
                        source="budget_in_principle.updated_at"
                        showTime
                    />
                    <TextField
                        label="Amount"
                        source="budget_in_principle.amount"
                    />
                    <TextField
                        label="Reference Code"
                        source="budget_in_principle.reference"
                    />

                    <BooleanField
                        label="Recalculation Required"
                        source="budget_in_principle.outdated"
                    />
                    <AppBudget data={budgetData} style={{ heigth: "100px" }} />
                </Tab>

                {record?.offer === null ? (
                    <Tab label="Keyzy Offer">
                        <h3>No offers</h3>
                    </Tab>
                ) : (
                    <Tab label="Keyzy Offer">
                        <TextField label="ID" source="offer.id" />
                        <DateField
                            label="Created At"
                            source="offer.created_at"
                            showTime
                        />

                        <DateField
                            label="Updated At"
                            source="offer.updated_at"
                            showTime
                        />

                        <TextField label="Offer Amount" source="offer.amount" />

                        <TextField
                            label="Contract Duration"
                            source="offer.duration"
                        />
                        <TextField
                            label="Early Repayment Fee"
                            source="offer.early_repayment_fee"
                        />
                        <TextField
                            label="Future Buyback"
                            source="offer.future_buy_back"
                        />
                        <TextField
                            label="Locked In Price"
                            source="offer.locked_in_price"
                        />
                        <TextField
                            label="Initial Fee"
                            source="offer.initial_fee"
                        />
                        <TextField
                            label="Survey and Valuation Fee"
                            source="offer.survey_fee"
                        />
                        <TextField
                            label="Monthly Rent"
                            source="offer.monthly_rent"
                        />
                        <TextField
                            label="Converted Payment"
                            source="offer.monthly_overpayment"
                        />
                        <TextField
                            label="Quote Accepted At"
                            source="offer.quote_accepted_at"
                        />
                    </Tab>
                )}
                <Tab label="Documents">
                    <Fragment>
                        <Button
                            color="primary"
                            onClick={() => toggleModal(true)}
                        >
                            {"Create new document"}
                        </Button>

                        <Modal
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            open={open}
                            onClose={(e) => toggleModal(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <DocumentCreate
                                {...props}
                                basePath="documents"
                                resource="documents"
                                application_id={props.id}
                                onComplete={() => {
                                    toggleModal(false);
                                    refresh();
                                }}
                            />
                        </Modal>
                        <List
                            {...props}
                            hasCreate={false}
                            exporter={false}
                            bulkActionButtons={
                                <BulkActionAppUnLinkDocumentsButton
                                    onComplete={() => {
                                        console.log("complete");
                                        refresh();
                                    }}
                                    applicationId={props.id}
                                />
                            }
                            resource="documents"
                            filter={{
                                applicationDocument: {
                                    some: {
                                        application_id: props.id,
                                    },
                                },
                            }}
                        >
                            <Datagrid>
                                <TextField label="id" source="id" />
                                <TextField label="File name" source="name" />
                                <TextField
                                    label="Description"
                                    source="description"
                                />
                                <TextField
                                    label="Box id"
                                    source="box_file_id"
                                />
                                <FunctionField
                                    render={(record) => (
                                        <Button
                                            color="secondary"
                                            onClick={() =>
                                                downloadDocument(
                                                    record?.id.toString(),
                                                )
                                            }
                                        >
                                            Download
                                        </Button>
                                    )}
                                    label=""
                                />
                            </Datagrid>
                        </List>
                    </Fragment>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};
