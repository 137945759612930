import { Create, SimpleForm, TextInput, useNotify } from "react-admin";

export default (props) => {
    const notify = useNotify();
    function OnFailure(error) {
        const errors: string[] =
            error.data.context?.errors || error.data.context;
        console.log(errors.toString());
        notify(errors.toString());
    }
    return (
        <Create {...props} onFailure={OnFailure}>
            <SimpleForm>
                <TextInput source="application_id" />
                <TextInput source="broker_id" />
            </SimpleForm>
        </Create>
    );
};
