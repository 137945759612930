import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Modal,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from "@material-ui/core";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { TopToolbar, EditButton } from "react-admin";
import {
    broker_approve,
    broker_reject,
} from "../../../api-requests/broker-update";
import { EntityStore } from "../../../store/entities";

export default ({ record }) => {
    let [open, toggleModal] = useState(false);

    async function createOffer() {
        try {
            await broker_approve(record?.id);
        } catch (err: any) {
            let message: string = `${err.message}\n`;

            let errors = (err as AxiosError).response?.data?.context?.errors;

            if (errors) {
                message = message.concat((errors as string[]).join("\n"));
                return alert(message);
            }
        } finally {
            toggleModal(false);
        }
    }
    async function rejectOffer() {
        try {
            await broker_reject(record?.id);
        } catch (err: any) {
            let message: string = `${err.message}\n`;

            let errors = (err as AxiosError).response?.data?.context?.errors;

            if (errors) {
                message = message.concat((errors as string[]).join("\n"));
                return alert(message);
            }
        } finally {
            toggleModal(false);
        }
    }

    return (
        <>
            <Modal
                open={open}
                onClose={(e) => toggleModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    alignSelf={"center"}
                    sx={{}}
                >
                    <Card className="p-2">
                        <CardHeader title="Approve Broker"></CardHeader>
                        <CardContent>
                            Please select Approve/Reject this user!
                        </CardContent>
                        <CardActions>
                            <Button color="primary" onClick={createOffer}>
                                Approve
                            </Button>
                            <Button color="secondary" onClick={rejectOffer}>
                                Reject
                            </Button>
                        </CardActions>
                    </Card>
                </Box>
            </Modal>

            <EditButton variant="outlined" record={record} />
            <Button
                color="primary"
                variant="contained"
                size="small"
                className="mx-2"
                onClick={() => toggleModal(!open)}
            >
                Approve Broker
            </Button>
        </>
    );
};
