import React, { Fragment } from "react";
import {
    Edit,
    useShowController,
    ShowButton,
    TopToolbar,
    Toolbar,
    SaveButton,
    SelectInput,
    SimpleForm,
    TabbedShowLayout,
    Tab,
} from "react-admin";

import {
    applicantIdVerificationUpdate,
    applicantIncomeVerificationUpdate,
} from "../../../api-requests/applicant-update";
import { ApplicantEdit } from "./applicant-edit";

const ApplicantActions = (props) => {
    let { record } = useShowController(props);

    return (
        <TopToolbar>
            <ShowButton className="px-6 py-8" record={record}></ShowButton>
        </TopToolbar>
    );
};

const UserEditToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton redirect="show" />
    </Toolbar>
);

const transform = (data) => {
    const { id, identity_verification, income_verification } = data;
    const idVerificationStatus = { status: identity_verification?.status };
    const incomeVerificationStatus = { status: income_verification?.status };
    async function updateIdAndIncomeVerification() {
        try {
            if (idVerificationStatus.status) {
                await applicantIdVerificationUpdate(id, idVerificationStatus);
            }
            if (incomeVerificationStatus.status) {
                await applicantIncomeVerificationUpdate(
                    id,
                    incomeVerificationStatus,
                );
            }
        } catch (err: any) {
            let message: string = `${err.message}\n`;
            return console.log("Error:", message);
        }
    }
    updateIdAndIncomeVerification();
    return {
        id: data.id,
        identity_verification: idVerificationStatus,
        income_verification: incomeVerificationStatus,
    };
};

export default (props) => {
    let { record } = useShowController(props);
    return (
        <TabbedShowLayout record={record}>
            <Tab label="Applicant">
                <Fragment>
                    <ApplicantEdit {...props} />
                </Fragment>
            </Tab>
            <Tab label="Identity Verification">
                <Fragment>
                    <Edit
                        {...props}
                        actions={<ApplicantActions {...props} />}
                        transform={transform}
                    >
                        <SimpleForm toolbar={<UserEditToolbar />}>
                            <SelectInput
                                source="identity_verification.status"
                                defaultValue=""
                                choices={[
                                    { id: "", name: "" },
                                    { id: "STARTED", name: "Started" },
                                    { id: "SUBMITTED", name: "Submitted" },
                                    { id: "APPROVED", name: "Approved" },
                                    {
                                        id: "RESUBMISSION_REQUESTED",
                                        name: "Resubmission requested",
                                    },
                                    { id: "DECLINED", name: "Declined" },
                                    { id: "EXPIRED", name: "Expired" },
                                    { id: "ABANDONED", name: "Abandoned" },
                                ]}
                            />
                        </SimpleForm>
                    </Edit>
                </Fragment>
            </Tab>
            <Tab label="Income Verification">
                <Fragment>
                    <Edit
                        {...props}
                        actions={<ApplicantActions {...props} />}
                        transform={transform}
                    >
                        <SimpleForm toolbar={<UserEditToolbar />}>
                            <SelectInput
                                source="income_verification.status"
                                defaultValue=""
                                choices={[
                                    { id: "", name: "" },
                                    { id: "LINKED", name: "Linked" },
                                    { id: "STARTED", name: "Started" },
                                    { id: "ERROR", name: "Error" },
                                ]}
                            />
                        </SimpleForm>
                    </Edit>
                </Fragment>
            </Tab>
        </TabbedShowLayout>
    );
};
