import {
    Edit,
    SimpleForm,
    TextInput,
    required,
    TextField,
    Toolbar,
    SaveButton,
    useNotify,
    BooleanInput,
    NumberInput,
} from "react-admin";
import { applicantUpdate } from "../../../api-requests/applicant-update";
import { pick } from "lodash";
export const ApplicantEdit = (props) => {
    const notify = useNotify();
    const transform = async (data) => {
        const response = await applicantUpdate(
            data.id,
            pick(data, [
                "first_name",
                "last_name",
                "email",
                "mobile_number",
                "has_savings",
                "savings",
            ]),
        ).catch((e) => {
            notify(e.response.data.error);
            throw e.response;
        });

        return response.data;
    };
    const validatePhone = (value) => {
        if (!value) {
            return undefined;
        }
        const pattern = /^\+44\s\d{4}-\d{6}$/;
        if (!pattern.test(value)) {
            return "Must be in format +44 ####-######";
        }
        return undefined;
    };

    const validateEmail = (value) => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(value) ? undefined : "Invalid email address";
    };
    const UserEditToolbar = (props) => (
        <Toolbar {...props}>
            <SaveButton redirect="show" />
        </Toolbar>
    );
    return (
        <Edit {...props} transform={transform}>
            <SimpleForm toolbar={<UserEditToolbar />}>
                <TextField label="Id" source="id" />
                <TextInput source="first_name" validate={required()} />
                <TextInput source="last_name" validate={required()} />
                <TextInput
                    label="Email Address"
                    source="email"
                    type="email"
                    validate={[required(), validateEmail]}
                />
                <TextInput source="mobile_number" validate={validatePhone} />
                <BooleanInput source="has_savings" />
                <NumberInput source="savings" />
            </SimpleForm>
        </Edit>
    );
};
