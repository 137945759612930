import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Modal,
    TextField,
} from "@material-ui/core";
import { AxiosError } from "axios";
import { useState } from "react";
import { create_offer } from "../../../api-requests/offer-create";
import { update_offer } from "../../../api-requests/offer-update";

export default ({ record }) => {
    let [open, toggleModal] = useState(false);
    let [formData, updateFormData] = useState({
        amount: "",
        duration: "",
        initial_fee: "",
        survey_fee: "",
        monthly_rent: "",
        monthly_overpayment: "",
        early_repayment_fee: "",
        future_buy_back: "",
        locked_in_price: "",
    });

    function _toggle() {
        record?.offer &&
            updateFormData({
                amount: record.offer?.amount ?? "",
                duration: record.offer?.duration ?? "",
                initial_fee: record.offer?.initial_fee ?? "",
                survey_fee: record.offer?.survey_fee ?? "",
                monthly_rent: record.offer?.monthly_rent ?? "",
                monthly_overpayment: record.offer?.monthly_overpayment ?? "",
                early_repayment_fee: record.offer?.early_repayment_fee ?? "",
                future_buy_back: record.offer?.future_buy_back ?? "",
                locked_in_price: record.offer?.locked_in_price ?? "",
            });
        toggleModal(!open);
    }

    let valueFormatter = {
        amount: (val) => parseFloat(val),
        duration: (val) => val,
        initial_fee: (val) => parseFloat(val),
        survey_fee: (val) => parseFloat(val),
        monthly_rent: (val) => parseFloat(val),
        monthly_overpayment: (val) => parseFloat(val),
        early_repayment_fee: (val) => val,
        future_buy_back: (val) => parseFloat(val),
        locked_in_price: (val) => parseFloat(val),
    };

    async function createOffer() {
        try {
            if (Boolean(record?.offer)) {
                await update_offer(
                    record.application_id ? record.application_id : record.id,
                    formData,
                );
                return;
            }

            await create_offer(record?.id, formData);
        } catch (err: any) {
            let message: string = `${err.message}\n`;

            let errors = (err as AxiosError).response?.data?.context?.errors;

            if (errors) {
                message = message.concat((errors as string[]).join("\n"));
            }

            alert(message);
        } finally {
            toggleModal(false);
        }
    }

    function handleFormChange(event) {
        let { name, value } = event.target;

        updateFormData({
            ...formData,
            [name]: valueFormatter[name](value),
        });
    }

    return (
        <>
            <Modal
                open={open}
                onClose={(e) => toggleModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box display="flex" alignItems="center" justifyContent="center">
                    <Card className="p-2">
                        <CardHeader
                            title={
                                record?.offer || record?.status === "APPROVED"
                                    ? "Update Offer"
                                    : "Create Offer"
                            }
                        ></CardHeader>
                        <CardContent>
                            <TextField
                                required
                                fullWidth
                                label="Amount"
                                type="number"
                                name="amount"
                                value={formData.amount}
                                onChange={handleFormChange}
                            />

                            <TextField
                                required
                                fullWidth
                                label="Duration"
                                name="duration"
                                value={formData.duration}
                                onChange={handleFormChange}
                            />

                            <TextField
                                required
                                fullWidth
                                label="Initial Fee"
                                type="number"
                                name="initial_fee"
                                value={formData.initial_fee}
                                onChange={handleFormChange}
                            />

                            <TextField
                                required
                                fullWidth
                                label="Survey and Valuation Fee"
                                type="number"
                                name="survey_fee"
                                value={formData.survey_fee}
                                onChange={handleFormChange}
                            />

                            <TextField
                                required
                                fullWidth
                                label="Monthly Rent"
                                type="number"
                                name="monthly_rent"
                                value={formData.monthly_rent}
                                onChange={handleFormChange}
                            />

                            <TextField
                                required
                                fullWidth
                                label="Monthly Repayment (Converted Payment)"
                                type="number"
                                name="monthly_overpayment"
                                value={formData.monthly_overpayment}
                                onChange={handleFormChange}
                            />

                            <TextField
                                required
                                fullWidth
                                label="Early Repayment Fee"
                                name="early_repayment_fee"
                                value={formData.early_repayment_fee}
                                onChange={handleFormChange}
                            />
                            <TextField
                                required
                                fullWidth
                                label="Future buy Back"
                                type="number"
                                name="future_buy_back"
                                value={formData.future_buy_back}
                                onChange={handleFormChange}
                            />
                            <TextField
                                required
                                fullWidth
                                label="Locked In Price"
                                type="number"
                                name="locked_in_price"
                                value={formData.locked_in_price}
                                onChange={handleFormChange}
                            />
                        </CardContent>
                        <CardActions>
                            <Button color="primary" onClick={createOffer}>
                                Submit
                            </Button>
                        </CardActions>
                    </Card>
                </Box>
            </Modal>

            <Button
                color="primary"
                variant={record?.status === "APPROVED" ? "contained" : "text"}
                onClick={_toggle}
            >
                {record?.offer || record?.offer_made ? "Update" : "Create"}{" "}
                Offer
            </Button>
        </>
    );
};
