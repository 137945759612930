import { Button } from "@material-ui/core";
import { AUTH_TOKEN } from "../../../react-admin/auth-provider";
import { $axios } from "../../../utils/axios";

export default ({ record }) => {
    let applicationId = record?.id;
    let actionHandler = async () => {
        let message = `This will send an 'Property bid won, offer extended' email the Customer, are you sure?`;

        if (!window.confirm(message)) return;

        await $axios({
            method: "post",
            url: `/admin/send-offer-ready-email/${applicationId}`,
            headers: {
                authorization: localStorage.getItem(AUTH_TOKEN)!,
            },
        });
    };

    return (
        <Button color="primary" onClick={actionHandler}>
            Send Offer Ready Email
        </Button>
    );
};
