import { AUTH_TOKEN } from "../react-admin/auth-provider";
import { $axios } from "../utils/axios";

export async function update_settings(data: {
    minimum_yield: string;
    converted_payment: string;
    fixed_costs: string;
}) {
    return $axios({
        url: `/admin/budget-calculator-settings`,
        method: "put",
        data,
        headers: {
            authorization: localStorage.getItem(AUTH_TOKEN)!,
        },
    });
}
