import fileDownload from "js-file-download";
import { $axios } from "../../../utils/axios";
import { AUTH_TOKEN } from "../../../react-admin/auth-provider";

export const downloadDocument = async (documentId?: string) => {
    if (!documentId) return;
    const response = await $axios({
        method: "get",
        url: `/documents/${documentId}/download`,
        headers: {
            authorization: localStorage.getItem(AUTH_TOKEN) ?? "",
        },
        responseType: "arraybuffer",
    });
    const fileName = response.headers["content-disposition"]
        ? response.headers["content-disposition"]
              .split(";")[1]
              .split("=")[1]
              .replaceAll('"', "")
        : "UnKnown file";
    fileDownload(response.data, fileName, response.headers["content-type"]);
};
