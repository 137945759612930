// import CreateOfferAction from "./create-offer";
import {
    Show,
    useShowController,
    TabbedShowLayout,
    Tab,
    TextField,
    DateField,
    TopToolbar,
    ReferenceField,
    FunctionField,
} from "react-admin";
import ApproveBroker from "./approve-broker";
import SendBrokerApprovalEmail from "./send-broker-approved-email";
import { BROKER_PROFESSIONS, BROKER_TYPES } from "../broker-update";

const ApplicationActions = ({ record }) => {
    return (
        <TopToolbar>
            <ApproveBroker record={record} />
            <SendBrokerApprovalEmail record={record} />
            <></>
        </TopToolbar>
    );
};

export default (props) => {
    let { record } = useShowController(props);

    return (
        <Show {...props} actions={<ApplicationActions record={record} />}>
            <TabbedShowLayout>
                <Tab label="Broker Details">
                    <TextField source="id"></TextField>
                    <TextField source="email"></TextField>
                    <TextField source="first_name"></TextField>
                    <TextField source="last_name"></TextField>
                    <TextField source="contact_number"></TextField>
                    <TextField source="company_name"></TextField>
                    <TextField source="referrer_mask"></TextField>
                    <ReferenceField
                        label="Entity"
                        source="entity_id"
                        reference="entities"
                        link="show"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField source="fca_number"></TextField>
                    <TextField source="network_name"></TextField>
                    <FunctionField
                        label="Type"
                        render={(broker) =>
                            BROKER_TYPES.find(
                                (broker_type) =>
                                    broker_type.id === broker?.type,
                            )?.name
                        }
                    />
                    <FunctionField
                        label="Profession"
                        render={(broker) =>
                            BROKER_PROFESSIONS.find(
                                (broker_profession) =>
                                    broker_profession.id === broker?.profession,
                            )?.name
                        }
                    />
                    <TextField source="status"></TextField>
                    <TextField
                        label="Password Created"
                        source="has_password"
                    ></TextField>
                    <DateField source="created_at" showTime />
                    <DateField
                        label="Last updated"
                        source="updated_at"
                        showTime
                    />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};
