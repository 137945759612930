import {
    TextField,
    BooleanField,
    DateField,
    Show,
    SimpleShowLayout,
    TabbedShowLayout,
    Tab,
    useShowController,
    NumberField,
    ArrayField,
    Datagrid,
    TopToolbar,
    EditButton,
    FunctionField,
    DeleteButton,
    EmailField,
} from "react-admin";

const IdentityVerification = () => {
    return (
        <SimpleShowLayout>
            <TextField
                source="identity_verification.id"
                label="Verification ID"
            />
            <DateField
                source="identity_verification.created_at"
                label="Created At"
                showTime
            />
            <DateField
                source="identity_verification.updated_at"
                label="Updated At"
                showTime
            />
            <TextField source="identity_verification.status" label="Status" />
            <TextField
                source="identity_verification.document_number"
                label="Document Number"
            />
        </SimpleShowLayout>
    );
};

const IncomeVerification = () => {
    return (
        <SimpleShowLayout>
            <DateField source="income_verification.created_at" showTime />
            <DateField source="income_verification.updated_at" showTime />
            <TextField source="income_verification.status" />
        </SimpleShowLayout>
    );
};

const IncomeBreakdown = () => {
    return (
        <ArrayField source="incomes" fieldKey="id">
            <Datagrid>
                <DateField source="created_at" showTime />
                <DateField source="updated_at" showTime />
                <TextField source="source_type" />
                <TextField source="source_description" />
                <TextField source="contract_type" />
                <TextField source="company_name" />
                <TextField source="job_title" />
                <TextField source="workplace_postal_code" />
                <TextField source="industry" />
                <DateField source="employment_started_at" showTime />
                <NumberField source="base_salary" />
                <NumberField source="bonus_income" />
                <NumberField source="commission" />
                <NumberField source="overtime_income" />
                <NumberField source="other_income" />
                <BooleanField source="is_primary" />
            </Datagrid>
        </ArrayField>
    );
};

const ApplicantActions = (props) => {
    let { record } = useShowController(props);
    return (
        <TopToolbar>
            <EditButton className="px-6 py-8" record={record}></EditButton>
            <DeleteButton
                className="px-6 py-8"
                record={record}
                disabled={record?.is_application_owner}
            ></DeleteButton>
        </TopToolbar>
    );
};

const ApplicationShow = (props) => {
    let { record } = useShowController(props);
    return (
        <Show {...props} actions={<ApplicantActions {...props} />}>
            <TabbedShowLayout record={record}>
                <Tab label="Applicant Info">
                    <TextField source="id" />
                    <DateField source="created_at" showTime />
                    <DateField source="updated_at" showTime />
                    <TextField source="formal_title" />
                    <TextField source="relationship_with_main_applicant" />
                    {/* <TextField source="full_name" /> */}
                    <TextField source="first_name" />
                    <TextField source="last_name" />
                    <DateField
                        source="date_of_birth"
                        showTime
                        locales={"en-GB"}
                        options={{ timeZone: "UTC" }}
                    />
                    <EmailField source="email" />
                    <TextField source="mobile_number" />
                    <TextField source="nationality" />
                    <TextField source="national_insurance_number" />
                    <TextField source="relationship_status" />
                    <TextField source="finance_adults" />
                    <TextField source="financed_children" />
                    <NumberField source="personal_loans" />
                    <NumberField source="student_loans" />
                    <NumberField source="vehicle_loans" />
                    <NumberField source="credit_cards_loans" />
                    <NumberField source="childcare_expenses" />
                    <NumberField source="car_finance" />
                    <NumberField source="maintenance_payments" />
                    <NumberField source="personal_loan_repayment" />
                    <NumberField source="csa_or_court_order_payment" />
                    <NumberField source="school_nursery_or_childcare_fees" />
                    <NumberField source="student_loan_repayment" />
                    <NumberField source="rent_payments" />
                    <NumberField source="unpaid_outstanding_balance" />
                    <BooleanField source="has_savings" />
                    <NumberField source="savings" />
                    <BooleanField source="has_existing_mortgage" />
                    <NumberField source="mortgage_repayment" />
                    <BooleanField
                        source="has_criminal_record"
                        label="IVA or Brpt 3yr"
                    />
                    <BooleanField source="has_fraud_or_crime" />
                    <BooleanField source="is_allowed_to_live_in_uk" />
                    <BooleanField source="missed_any_payments" />
                    <BooleanField source="income_more_than_30k" />
                    <BooleanField source="is_application_owner" />
                    <BooleanField
                        source="is_bankrupt_or_iva"
                        label="CCJ or Default >£250 12m"
                    />
                    <BooleanField source="is_on_the_voters_roll" />
                    <BooleanField source="is_first_time_buyer" />
                    <FunctionField
                        render={(record) =>
                            record?.is_moving_or_buying === "BUYING"
                                ? "NO"
                                : "YES"
                        }
                        label="Primary residence?"
                    />
                </Tab>
                <Tab label="Identity Verification">
                    <IdentityVerification {...props} />
                </Tab>

                <Tab label="Income Verification">
                    <IncomeVerification {...props} />
                </Tab>

                <Tab label="Historical Addresses">
                    <ArrayField source="historical_addresses" fieldKey="id">
                        <Datagrid>
                            <DateField source="created_at" showTime />
                            <DateField source="updated_at" showTime />
                            <TextField source="country_code" />
                            <TextField source="city" />
                            <TextField source="postal_code" />
                            <TextField source="street" />
                            <DateField source="date_moved_in" showTime />
                        </Datagrid>
                    </ArrayField>
                </Tab>

                <Tab label="Income Breakdown">
                    <IncomeBreakdown {...props} />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export default ApplicationShow;
