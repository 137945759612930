import { Admin, Resource } from "react-admin";
import dataProvider from "./react-admin/data-provider";
import authProvider from "./react-admin/auth-provider";
import applicationList from "./components/applications/application-list";
import brokersList from "./components/brokers/broker-list";
import brokersShow from "./components/brokers/broker-show";
import brokersUpdate from "./components/brokers/broker-update";
import applicationShow from "./components/applications/application-show";
import applicantUpdate from "./components/applications/applicant-update";
import applicantShow from "./components/applications/applicant-show";
import entitiesList from "./components/entities";
import propertiesList from "./components/properties";
import Dashboard from "./components/dashboard";
import BusinessIcon from "@material-ui/icons/Business";
import LocationCity from "@material-ui/icons/LocationCity";
import Attachment from "@material-ui/icons/Attachment";
import House from "@material-ui/icons/House";
import entityDetails from "./components/entities/entity-details";
import propertyDetails from "./components/properties/property-details";
import applicationUpdate from "./components/applications/application-show/application-update";
import brokerReferralsList from "./components/broker-referrals/broker-referrals-list";
import brokerReferralsCreate from "./components/broker-referrals/broker-referrals-create";

import documentList from "./components/documents";
import { DocumentCreate } from "./components/documents/create-document";
import { DocumentShow } from "./components/documents/show-document";
import { DocumentEdit } from "./components/documents/edit-document";
import listingList from "./components/listings";
import { Map } from "@material-ui/icons";
export default () => {
    return (
        <Admin
            dataProvider={dataProvider}
            authProvider={authProvider}
            dashboard={Dashboard}
        >
            <Resource
                name="applications"
                list={applicationList}
                show={applicationShow}
                edit={applicationUpdate}
            />

            <Resource
                name="properties"
                list={propertiesList}
                icon={House}
                show={propertyDetails}
            />

            <Resource
                name="brokers"
                list={brokersList}
                show={brokersShow}
                icon={LocationCity}
                edit={brokersUpdate}
            />

            <Resource
                name="broker-referrals"
                options={{ label: "Broker Referrals" }}
                icon={Attachment}
                list={brokerReferralsList}
                create={brokerReferralsCreate}
            />

            <Resource
                name="applicants"
                list={undefined}
                show={applicantShow}
                edit={applicantUpdate}
            />

            <Resource
                name="entities"
                list={entitiesList}
                icon={BusinessIcon}
                show={entityDetails}
                // edit={EditGuesser}
            />

            <Resource
                name="documents"
                create={DocumentCreate}
                list={documentList}
                show={DocumentShow}
                edit={DocumentEdit}
            />

            <Resource name="listings" icon={Map} list={listingList} />
        </Admin>
    );
};
