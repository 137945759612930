import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Modal,
    TextField as TextFieldModal,
    InputLabel,
    Select,
    MenuItem
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { reject_property } from "../../api-requests/reject-property";

export default (props) => {
    let [open, toggleModal] = useState(false);
    const [status, setStatus] = useState('LOST');

    useEffect(() => {
        toggleModal(props.isOpen);
    }, [props.isOpen]);

    let [formData, updateFormData] = useState({
        fail_reason: "",
        status: "LOST",
    });

    let valueFormatter = {
        status: (val: string) => val,
        fail_reason: (val: string) => val,
    };

    function handleFormChange(event) {
        setStatus(event.target.value as string);
        let { name, value } = event.target;
        updateFormData({
            ...formData,
            [name]: valueFormatter[name](value),
        });
    };


    async function rejectProperty() {
        try {
            await reject_property(formData, props.id);
        } catch (err: any) {
            let message: string = `${err.message}\n`;

            let errors = (err as AxiosError).response?.data?.context?.errors;

            if (errors) {
                message = message.concat((errors as string[]).join("\n"));
                return alert(message);
            }
        } finally {
            toggleModal(false);
        }
    };


    return (
        <Modal
            open={open}
            onClose={(e) => {
                toggleModal(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                display="block"
                alignItems="center"
                justifyContent="center"
                sx={{ paddingTop: "50px;", margin: "0 auto", width: "50%" }}
            >
                <Card className="p-2">
                    <CardHeader title="Reject property"></CardHeader>
                    <CardContent>
                        <div>
                            <InputLabel id="rejectStatus">Reject Status</InputLabel>
                            <Select
                                labelId="rejectStatus"
                                id="rejectStatus"
                                label="Reject Status"
                                name="status"
                                value={status}
                                onChange={handleFormChange}
                            >
                                <MenuItem value="LOST">LOST</MenuItem>
                                <MenuItem value="REJECTED">REJECTED</MenuItem>
                            </Select>
                        </div>
                        <div>
                            <TextFieldModal
                                id="rejectReason"
                                label="Fail reason"
                                name="fail_reason"
                                multiline
                                onChange={handleFormChange}
                            />
                        </div>
                    </CardContent>
                    <CardActions>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                rejectProperty();
                            }}
                        >
                            Reject property
                        </Button>
                    </CardActions>
                </Card>
            </Box>
        </Modal>
    );
};
