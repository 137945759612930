import { Fragment } from "react";
import {
    TextField,
    Show,
    TabbedShowLayout,
    Tab,
    DateField,
    Datagrid,
    List,
    BulkActionProps,
    Identifier,
    useListController,
    FunctionField,
} from "react-admin";
import { Button } from "@material-ui/core";
import { $axios } from "../../utils/axios";
import { AUTH_TOKEN } from "../../react-admin/auth-provider";
import fileDownload from "js-file-download";
import { downloadDocument } from "./utils/download-document";

interface BulkActionOnActionProps extends BulkActionProps {
    onAction: (selectedIds?: Identifier[]) => void;
}

export const DocumentShow = (props) => {
    const { refetch, selectedIds: outerSele } = useListController(props);
    const download = async (documentId?: string) => {
        if (!documentId) return;
        const response = await $axios({
            method: "get",
            url: `/documents/${documentId}/download`,
            headers: {
                authorization: localStorage.getItem(AUTH_TOKEN) ?? "",
            },
        });
        const fileName = response.headers["content-disposition"]
            ? response.headers["content-disposition"]
                  .split(";")[1]
                  .split("=")[1]
                  .replaceAll('"', "")
            : "UnKnown file";
        fileDownload(response.data, fileName, response.headers["content-type"]);
    };
    return (
        <Show {...props}>
            <TabbedShowLayout>
                <Tab label="Info">
                    <TextField source="id" />
                    <DateField
                        source="created_at"
                        label="Created At"
                        showTime
                    />
                    <TextField label="File name" source="name" />
                    <TextField label="Description" source="description" />
                    <TextField label="Box id" source="box_file_id" />
                    <FunctionField
                        render={(record) => (
                            <Button
                                color="secondary"
                                onClick={() =>
                                    downloadDocument(record?.id.toString())
                                }
                            >
                                Download
                            </Button>
                        )}
                        label="Download"
                    />
                </Tab>
                <Tab label="Applications">
                    <Fragment>
                        {/* <ArrayField source="application_documents" fieldKey="id"> */}
                        <List
                            {...props}
                            hasCreate={false}
                            resource="applications"
                            exporter={false}
                            filter={{
                                applicationDocument: {
                                    some: {
                                        document_id: props.id,
                                    },
                                },
                            }}
                            bulkActionButtons={false}
                        >
                            <Datagrid basePath="applications/">
                                <TextField source="id" />
                                <TextField label="Status" source="status" />
                                <TextField
                                    label="Prefered Location"
                                    source="application.preferredLocation"
                                />
                            </Datagrid>
                        </List>
                    </Fragment>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};
