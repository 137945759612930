import { Button } from "@material-ui/core";
import { TopToolbar } from "react-admin";
import { send_bip_ready_email } from "../../../api-requests/email-budget-ready";

export default ({ record }) => {
    let applicantId = record?.applicants
        ?.filter((applicant) => applicant.is_application_owner)
        .shift()?.id;

    let actionHandler = async () => {
        let message = `This will send and email to the customer, are you sure?`;

        if (!window.confirm(message)) return;

        await send_bip_ready_email({ applicantId });
    };

    return (
        <Button color="primary" onClick={actionHandler}>
            Send BIP Email
        </Button>
    );
};
