import {
    TextField,
    Edit,
    SimpleForm,
    SelectInput,
    TextInput,
    useShowController,
    BooleanInput,
} from "react-admin";
import { pick } from "lodash";

export default (props) => {
    let { record } = useShowController(props);

    function transform(record) {
        return pick(record, ["notes", "status", "only_keyzy_now"]);
    }

    function getMainApplicant() {
        return (
            record?.applicants
                ?.filter((applicant) => applicant.is_application_owner)
                .shift() ?? {}
        );
    }

    return (
        <Edit {...props} mutationMode="optimistic" transform={transform}>
            <SimpleForm>
                <TextField source="id" />

                <h3>Main Applicant</h3>

                <span>{getMainApplicant().full_name}</span>
                <span>{getMainApplicant().email}</span>

                <TextInput label="Notes" source="notes" />

                <SelectInput
                    source="status"
                    choices={[
                        {
                            id: "DRAFT",
                            name: "Draft",
                        },
                        {
                            id: "NOT_ELIGIBLE",
                            name: "Not Eligible",
                        },
                        {
                            id: "INCOMPLETE",
                            name: "Incomplete",
                        },
                        {
                            id: "AWAITING_UNDERWRITING",
                            name: "Awaiting Underwriting",
                        },
                        {
                            id: "FINDING_PROPERTY",
                            name: "Finding Property",
                        },
                        {
                            id: "AWAITING_PROPERTY_REVIEW",
                            name: "Awaiting Property Review",
                        },
                        {
                            id: "QUOTE_PREVIEW",
                            name: "Quote Preview",
                        },
                        {
                            id: "AWAITING_BIDDING_RESULT",
                            name: "Awaiting Bidding Result",
                        },
                        {
                            id: "OFFER_EXTENDED",
                            name: "Offer Extended",
                        },
                        {
                            id: "AWAITING_PROPERTY_DILIGENCE",
                            name: "Awaiting Property Diligence",
                        },
                        {
                            id: "AWAITING_EXCHANGE",
                            name: "Awaiting Exchange - Deprecated",
                        },
                        {
                            id: "AWAITING_COMPLETION",
                            name: "Awaiting Completion",
                        },
                        {
                            id: "COMPLETED",
                            name: "Completed - Deprecated",
                        },
                        {
                            id: "COMPLETION",
                            name: "Completion",
                        },
                        {
                            id: "MOVED_IN",
                            name: "Moved in",
                        },
                        {
                            id: "CANCELLED",
                            name: "Cancelled",
                        },
                        {
                            id: "REJECTED",
                            name: "Rejected",
                        },
                    ]}
                />
                <BooleanInput source="only_keyzy_now" />
            </SimpleForm>
        </Edit>
    );
};
