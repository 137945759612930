import {
    List,
    Datagrid,
    TextField,
    DateField,
    SearchInput,
    TextInput,
    FunctionField,
    BooleanField,
    BooleanInput,
} from "react-admin";
const postFilters = [
    <SearchInput source="id" alwaysOn />,
    <TextInput label="Application Owner" source="name" />,
    <BooleanInput label="Only keyzy now" source="only_keyzy_now" />,
];

export default (props) => (
    <List
        {...props}
        filters={postFilters}
        sort={{ field: "updated_at", order: "DESC" }}
    >
        <Datagrid rowClick="show">
            <TextField source="id" />
            <DateField label="Last Updated" source="updated_at" showTime />
            <FunctionField
                label={"Appliaction Owner"}
                render={(props) =>
                    `${props?.application_owner?.first_name || ""} ${
                        props?.application_owner?.last_name || ""
                    }`
                }
            />
            <TextField source="status" />
            <TextField source="notes" sortable={false} />
            <BooleanField source="only_keyzy_now" sortable={false} />
        </Datagrid>
    </List>
);
