import { AUTH_TOKEN } from "../react-admin/auth-provider";
import { $axios } from "../utils/axios";

export async function applicantUpdate(
    applicantId: string,
    payload: Record<string, any>,
) {
    return $axios.put(`/applicants/${applicantId}`, payload, {
        headers: {
            authorization: localStorage.getItem(AUTH_TOKEN) ?? "",
        },
    });
}

export async function applicantIdVerificationUpdate(
    applicantId: string,
    payload: Record<string, any>,
) {
    return $axios.put(
        `/applicants/${applicantId}/identity-verification`,
        payload,
        {
            headers: {
                authorization: localStorage.getItem(AUTH_TOKEN) ?? "",
            },
        },
    );
}
export async function applicantIncomeVerificationUpdate(
    applicantId: string,
    payload: Record<string, any>,
) {
    return $axios.put(
        `/applicants/${applicantId}/income-verification`,
        payload,
        {
            headers: {
                authorization: localStorage.getItem(AUTH_TOKEN) ?? "",
            },
        },
    );
}
