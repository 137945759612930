import { List, TextField, DateField, Datagrid, TopToolbar } from "react-admin";
import { Button } from "@material-ui/core";
import CreateEntity from "./create-entity";
import { useState } from "react";

export default (props) => {
    let [open, openPopup] = useState(false);

    function resetAndOpenButton() {
        openPopup(false);
        setTimeout(() => {
            openPopup(true);
        }, 100);
    };

    return (
        <>
            <TopToolbar>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={(e) => resetAndOpenButton()}
                >
                    Create Entity
                </Button>
            </TopToolbar>

            <List {...props}>
                <Datagrid rowClick="show">
                    <TextField label="Entity Name" source="name" />
                    <DateField
                        label="Last Updated"
                        source="updated_at"
                        showTime
                    />
                    <TextField source="fca_number" label="FCA number" />
                    <TextField source="contact_name" sortable={false} />
                    <TextField source="contact_email" />
                    <TextField source="contact_number" />
                    <TextField source="agreement_signed" />
                </Datagrid>
            </List>
            <CreateEntity isOpen={open} />
        </>
    );
};
