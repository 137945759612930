import {
    AutocompleteArrayInput,
    AutocompleteInput,
    Create,
    ReferenceInput,
    SimpleForm,
    TextInput,
    required,
    useNotify,
    useRedirect,
} from "react-admin";
import { $axios } from "../../utils/axios";
import { AUTH_TOKEN } from "../../react-admin/auth-provider";
export const DocumentCreate = (props) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const linkDocument = async (documentId: string) => {
        if (props.application_id) {
            await $axios.post(
                `/documents/${documentId}/link`,
                { application_id: props.id },
                {
                    headers: {
                        authorization: localStorage.getItem(AUTH_TOKEN) ?? "",
                    },
                },
            );
            notify(`Document created and linked to application`);
            props.onComplete && props.onComplete();
        } else {
            notify(`Document created`);
            redirect(`/documents/`);
        }
    };
    return (
        <Create {...props} onSuccess={(data) => linkDocument(data.data.id)}>
            <SimpleForm>
                <TextInput source="name" validate={[required()]} />
                <TextInput
                    source="description"
                    validate={[required()]}
                    fullWidth
                />
                <TextInput source="box_file_id" validate={[required()]} />
            </SimpleForm>
        </Create>
    );
};
