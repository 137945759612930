import { useQuery } from "react-query";
import { $axios } from "../../utils/axios";
import { AUTH_TOKEN } from "../../react-admin/auth-provider";

export interface ListingPropertyParams {
    postcode?: string;
    distance?: number;
}
export interface ListingProperty {
    id: number;
    geom: {
        coordinates: number[];
        crs: {
            properties: {
                name: string;
            };
            type: string;
        };
    };
}
export const GetListingProperties = (params: ListingPropertyParams) =>
    useQuery(
        ["getListingProperties", params],
        () => {
            return $axios({
                method: "get",
                url: `/admin/listings/properties`,
                params: {
                    postcode: params.postcode,
                    distance: params.distance
                        ? params.distance * 1000
                        : params.distance,
                },
                headers: {
                    authorization: localStorage.getItem(AUTH_TOKEN)!,
                },
            }).then((res) => res.data as ListingProperty[]);
        },
        {
            staleTime: Infinity,
        },
    );
