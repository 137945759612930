import { Button } from "@material-ui/core";
import { AxiosError } from "axios";
import { create_offer } from "../../../api-requests/offer-create";
import { update_offer } from "../../../api-requests/offer-update";
import { AUTH_TOKEN } from "../../../react-admin/auth-provider";
import { $axios } from "../../../utils/axios";

export default ({ record }) => {
    async function _action() {
        if (
            window.confirm(
                `Are you sure you want to send a broker approval email to ${record?.first_name} ${record?.last_name}?`,
            )
        ) {
            await sendEmail();
        }
    }

    async function sendEmail() {
        try {
            await $axios({
                method: "post",
                url: `/admin/send-broker-approval-email/${record?.id}`,
                headers: {
                    authorization: localStorage.getItem(AUTH_TOKEN) ?? "",
                },
            });
        } catch (err: any) {
            let message: string = `${err.message}\n`;

            let errors = (err as AxiosError).response?.data?.context?.errors;

            if (errors) {
                message = message.concat((errors as string[]).join("\n"));
            }

            alert(message);
        }
    }

    return (
        <Button color="primary" onClick={_action}>
            Send Broker Approval Email
        </Button>
    );
};
