import { AUTH_TOKEN } from "../react-admin/auth-provider";
import { $axios } from "../utils/axios";

export async function send_bip_ready_email({ applicantId }) {
    return $axios({
        method: "post",
        url: `/admin/applicants/${applicantId}/email/bip-is-ready`,
        headers: {
            authorization: localStorage.getItem(AUTH_TOKEN)!,
        },
    });
}
