import { List, TextField, DateField, Datagrid, TopToolbar, SearchInput } from "react-admin";


const postFilters = [
    <SearchInput source="application_id" alwaysOn />,
];

export default (props) => {
    return (
        <>
            <List {...props} filters={postFilters} sort={{ field: "updated_at", order: "DESC" }}>
                <Datagrid rowClick="show">
                    <TextField label="Application ID" source="application_id" />
                    <TextField label="Address" source="address" />
                    <DateField
                        label="Last Updated"
                        source="updated_at"
                        showTime
                    />
                    <TextField source="property_viewed_by_customer" />
                    <TextField source="status" />
                    <TextField source="active" />
                </Datagrid>
            </List>

        </>
    );
};
