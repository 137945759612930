import { Button } from "@material-ui/core";
import { useState } from "react";
import {
    Show,
    TabbedShowLayout,
    Tab,
    TextField,
    TopToolbar,
    TextInput,
    NumberInput,
    SelectInput,
    FormTab,
    Edit,
    TabbedForm,
    ShowButton,
    Toolbar,
    SaveButton,
} from "react-admin";

export default (props) => {
    let [editingMode, updateEntity] = useState(false);

    const agreementSigned = ["false", "true"];

    const isSigned = (items) => items.map((item) => ({ id: item, name: item }));

    const transform = (data) => ({
        ...data,
        fca_number: data.fca_number.toString(),
        commission_rate: data.commission_rate.toString(),
        contact_number: !!data.contact_number && data.contact_number.toString() ||"",
        agreement_signed:
            data.agreement_signed === "true" || data.agreement_signed === true,
    });

    const UserEditToolbar = (props) => (
        <Toolbar {...props}>
            <SaveButton />
        </Toolbar>
    );

    const ShowButtonAction = (props) => (
        <TopToolbar>
            {editingMode ? (
                <ShowButton
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        updateEntity(false);
                    }}
                />
            ) : (
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        updateEntity(true);
                    }}
                >
                    Update entity
                </Button>
            )}
        </TopToolbar>
    );
    return (
        <>
            {editingMode ? (
                <Edit
                    {...props}
                    actions={<ShowButtonAction />}
                    transform={transform}
                >
                    <TabbedForm toolbar={<UserEditToolbar />}>
                        <FormTab label="Update Entity">
                            <TextInput
                                label="Entity Name"
                                source="name"
                                defaultValue="name"
                            />
                            <TextInput disabled source="id"></TextInput>
                            <TextInput disabled source="created_at"></TextInput>
                            <TextInput disabled source="updated_at"></TextInput>
                            <NumberInput
                                label="FCA number"
                                source="fca_number"
                                defaultValue="fca_number"
                            />
                            <TextInput
                                label="Contact name"
                                source="contact_name"
                                defaultValue="contact_name"
                            />
                            <TextInput
                                label="Contact email"
                                source="contact_email"
                                defaultValue="contact_email"
                            />
                            <NumberInput
                                label="Contact number"
                                source="contact_number"
                                defaultValue="contact_number"
                            />
                            <TextInput
                                label="Postcode"
                                source="postcode"
                                defaultValue="postcode"
                            />
                            <NumberInput
                                label="Commission rate"
                                source="commission_rate"
                                defaultValue="commission_rate"
                            />
                            <SelectInput
                                label="Agreement signed"
                                source="agreement_signed"
                                defaultValue="agreement_signed"
                                choices={isSigned(agreementSigned)}
                            />
                        </FormTab>
                    </TabbedForm>
                </Edit>
            ) : (
                <Show {...props} actions={<ShowButtonAction />}>
                    <TabbedShowLayout>
                        <Tab label="Entity Details">
                            <TextField source="name"></TextField>
                            <TextField source="id"></TextField>
                            <TextField source="created_at"></TextField>
                            <TextField source="updated_at"></TextField>
                            <TextField source="fca_number"></TextField>
                            <TextField source="contact_name"></TextField>
                            <TextField source="contact_email"></TextField>
                            <TextField source="contact_number"></TextField>
                            <TextField source="postcode"></TextField>
                            <TextField source="commission_rate"></TextField>
                            <TextField source="agreement_signed"></TextField>
                        </Tab>
                    </TabbedShowLayout>
                </Show>
            )}
        </>
    );
};
