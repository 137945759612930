import { AUTH_TOKEN } from "../react-admin/auth-provider";
import { $axios } from "../utils/axios";

export async function create_budget_in_principle(
    applicationId: string,
    payload: Record<string, any>,
) {
    return $axios.post(
        `/applications/${applicationId}/budget-in-principle`,
        payload,
        {
            headers: {
                authorization: localStorage.getItem(AUTH_TOKEN) ?? "",
            },
        },
    );
}
