import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    FunctionField,
} from "react-admin";
import { BROKER_PROFESSIONS, BROKER_TYPES } from "../broker-update";

export default (props) => (
    <List {...props} sort={{ field: "created_at", order: "DESC" }} hasEdit>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField label="First Name" source="first_name" />
            <TextField label="Last Name" source="last_name" />
            <TextField label="Company Name" source="company_name" />
            <TextField label="Referrer Mask" source="referrer_mask" />
            <FunctionField
                label="Type"
                render={(broker) =>
                    BROKER_TYPES.find(
                        (broker_type) => broker_type.id === broker?.type,
                    )?.name
                }
            />
            <FunctionField
                label="Profession"
                render={(broker) =>
                    BROKER_PROFESSIONS.find(
                        (broker_profession) =>
                            broker_profession.id === broker?.profession,
                    )?.name
                }
            />
            <DateField label="Created" source="created_at" showTime />
            <DateField label="Last Updated" source="updated_at" showTime />
            <TextField source="status" />
            <EditButton />
        </Datagrid>
    </List>
);
