import { AUTH_TOKEN } from "../react-admin/auth-provider";
import { $axios } from "../utils/axios";

export async function create_standalone_budget_pdf(payload: any) {
    return $axios.post(`/admin/stand-alone-budget`, payload, {
        headers: {
            authorization: localStorage.getItem(AUTH_TOKEN) ?? "",
        },
        responseType: "arraybuffer",
    });
}
