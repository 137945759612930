import {
    List,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
} from "react-admin";

export default (props) => (
    <List {...props}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <DateField label="Last Updated" source="updated_at" showTime />
            <DateField label="Last Updated" source="created_at" showTime />

            <TextField source="referred_name" />
            <TextField source="referred_number" />
            <TextField source="referred_phone" />
            <TextField source="referred_email" />

            <ReferenceField
                reference="brokers"
                label="Broker First name"
                source="broker_id"
                link="show"
            >
                <TextField source="first_name" />
            </ReferenceField>
            <ReferenceField
                reference="brokers"
                label="Broker Last name"
                source="broker_id"
                link="show"
            >
                <TextField source="last_name" />
            </ReferenceField>

            <ReferenceField
                reference="entities"
                label="Entity"
                source="entity_id"
                link="show"
            >
                <TextField source="name" />
            </ReferenceField>

            <ReferenceField
                reference="applications"
                label="Application"
                source="application_id"
                link="show"
            >
                <TextField source="id" />
            </ReferenceField>
        </Datagrid>
    </List>
);
