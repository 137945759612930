import { AUTH_TOKEN } from "../react-admin/auth-provider";
import { $axios } from "../utils/axios";

export async function update_budget_in_principle(
    applicationId: string,
    body: Record<string, any>,
) {
    return $axios({
        method: "put",
        url: `/applications/${applicationId}/budget-in-principle`,
        data: body,
        headers: {
            authorization: localStorage.getItem(AUTH_TOKEN)!,
        },
    });
}
