import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import { Link } from "@material-ui/core";
import ManageOffer from "../applications/application-show/manage-offer";
import {
    Show,
    TabbedShowLayout,
    Tab,
    TextField,
    TopToolbar,
    TextInput,
    NumberInput,
    SelectInput,
    FormTab,
    Edit,
    TabbedForm,
    ShowButton,
    Toolbar,
    SaveButton,
    useShowController,
    useRecordContext,
} from "react-admin";
import ApproveProperty from "./approve-property";
import RejectProperty from "./reject-property";

const useStyles = makeStyles(() => ({
    root: {
        "& > *": {
            margin: "15px",
        },
    },
}));

export default (props) => {
    let [editingMode, updateProperty] = useState(false);
    let { record } = useShowController(props);
    let [approvePopupOpened, openApprovePopup] = useState(false);
    let [rejectPopupOpened, openRejectPopup] = useState(false);

    const ApplicationShowLink = ({ source }) => {
        const record = useRecordContext();
        const value = record && record[source];
        if (value == null) {
            return null;
        }

        return <Link href={`#/applications/${value}/show`}>{value}</Link>;
    };

    ApplicationShowLink.defaultProps = {
        addLabel: true,
    };

    function resetAndOpenApprove() {
        openApprovePopup(false);
        setTimeout(() => {
            openApprovePopup(true);
        }, 100);
    }

    function resetAndOpenReject() {
        openRejectPopup(false);
        setTimeout(() => {
            openRejectPopup(true);
        }, 100);
    }

    const trueOrFalse = ["false", "true"];
    const setTrueOrFalse = (items) =>
        items.map((item) => ({ id: item, name: item }));
    const classes = useStyles();
    const transform = (data) => ({
        ...data,
        active: data.active === "true" || data.active === true,
        using_estate_agent:
            data.using_estate_agent === "true" ||
            data.using_estate_agent === true,
        offer_made: data.offer_made === "true" || data.offer_made === true,
        property_viewed_by_customer:
            data.property_viewed_by_customer === "true" ||
            data.property_viewed_by_customer === true,
        has_parking_or_garage:
            data.has_parking_or_garage === "true" ||
            data.has_parking_or_garage === true,
        has_outdoor_space:
            data.has_outdoor_space === "true" ||
            data.has_outdoor_space === true,
    });

    const UserEditToolbar = (props) => (
        <Toolbar {...props}>
            <SaveButton />
        </Toolbar>
    );

    const ShowButtonAction = (props) => (
        <TopToolbar>
            {editingMode ? (
                <ShowButton
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        updateProperty(false);
                    }}
                />
            ) : (
                <div className={classes.root}>
                    {record?.status === "SUBMITTED" && (
                        <Button
                            id="approveButton"
                            color="primary"
                            variant="contained"
                            onClick={(e) => resetAndOpenApprove()}
                        >
                            Approve Property
                        </Button>
                    )}
                    {record?.status === "REJECTED" ? (
                        ""
                    ) : (
                        <>
                            <Button
                                id="rejectButton"
                                color="primary"
                                variant="contained"
                                onClick={(e) => resetAndOpenReject()}
                            >
                                Reject Property
                            </Button>
                        </>
                    )}
                    {record?.status === "APPROVED" && (
                        <ManageOffer record={record} />
                    )}
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            updateProperty(true);
                        }}
                    >
                        Update property
                    </Button>
                </div>
            )}
        </TopToolbar>
    );
    return (
        <>
            {editingMode ? (
                <Edit
                    {...props}
                    actions={<ShowButtonAction />}
                    transform={transform}
                >
                    <TabbedForm toolbar={<UserEditToolbar />}>
                        <FormTab label="Update Property">
                            <TextInput
                                label="Application ID of property"
                                source="application_id"
                                defaultValue="application_id"
                                disabled
                            />
                            <TextInput disabled source="id"></TextInput>
                            <TextInput disabled source="created_at"></TextInput>
                            <TextInput disabled source="updated_at"></TextInput>
                            <SelectInput
                                label="Active"
                                source="active"
                                defaultValue="active"
                                choices={setTrueOrFalse(trueOrFalse)}
                            />
                            <SelectInput
                                label="Using estate agent"
                                source="using_estate_agent"
                                defaultValue="using_estate_agent"
                                choices={setTrueOrFalse(trueOrFalse)}
                            />
                            <SelectInput
                                source="status"
                                choices={[
                                    { id: "SUBMITTED", name: "Submitted" },
                                    { id: "APPROVED", name: "Approved" },
                                    { id: "CREATED", name: "Created" },
                                    { id: "LOST", name: "Lost" },
                                    { id: "REJECTED", name: "Rejected" },
                                ]}
                            />
                            <TextInput
                                label="Address"
                                source="address"
                                defaultValue="address"
                            />
                            <TextInput
                                label="Agent company name"
                                source="agent_company_name"
                                defaultValue="agent_company_name"
                            />
                            <TextInput
                                label="Agent email"
                                source="agent_email"
                                defaultValue="agent_email"
                            />
                            <TextInput
                                label="Agent name"
                                source="agent_name"
                                defaultValue="agent_name"
                            />
                            <TextInput
                                label="Agent phone number"
                                source="agent_phone_number"
                                defaultValue="agent_phone_number"
                            />
                            <NumberInput
                                label="Asking price"
                                source="asking_price"
                                defaultValue="asking_price"
                            />
                            <TextInput
                                label="Building type"
                                source="building_type"
                                defaultValue="building_type"
                            />
                            <TextInput
                                label="City Or Town"
                                source="city_or_town"
                                defaultValue="city_or_town"
                            />
                            <TextInput
                                label="Floor number"
                                source="floor_number"
                                defaultValue="floor_number"
                            />
                            <NumberInput
                                label="Ground rent"
                                source="ground_rent"
                                defaultValue="ground_rent"
                            />
                            <SelectInput
                                label="Has outdoor space"
                                source="has_outdoor_space"
                                defaultValue="has_outdoor_space"
                                choices={setTrueOrFalse(trueOrFalse)}
                            />
                            <SelectInput
                                label="Has parking or garage"
                                source="has_parking_or_garage"
                                defaultValue="has_parking_or_garage"
                                choices={setTrueOrFalse(trueOrFalse)}
                            />
                            <TextInput
                                label="House number"
                                source="house_number"
                                defaultValue="house_number"
                            />
                            <TextInput
                                label="House type"
                                source="house_type"
                                defaultValue="house_type"
                            />
                            <NumberInput
                                label="Internal area"
                                source="internal_area"
                                defaultValue="internal_area"
                            />
                            <TextInput
                                label="Lease type"
                                source="lease_type"
                                defaultValue="lease_type"
                            />
                            <TextInput
                                label="Number of bathrooms"
                                source="number_of_bathrooms"
                                defaultValue="number_of_bathrooms"
                            />
                            <TextInput
                                label="Number of bedrooms"
                                source="number_of_bedrooms"
                                defaultValue="number_of_bedrooms"
                            />
                            <TextInput
                                label="Number of floors"
                                source="number_of_floors"
                                defaultValue="number_of_floors"
                            />
                            <TextInput
                                label="Number of other rooms"
                                source="number_of_other_rooms"
                                defaultValue="number_of_other_rooms"
                            />
                            <TextInput
                                label="Offer accepted at"
                                source="offer_accepted_at"
                                defaultValue="offer_accepted_at"
                            />
                            <NumberInput
                                label="Offer amount"
                                source="offer_amount"
                                defaultValue="offer_amount"
                            />
                            <SelectInput
                                label="Offer made"
                                source="offer_made"
                                defaultValue="offer_made"
                                choices={setTrueOrFalse(trueOrFalse)}
                            />
                            <TextInput
                                label="Offer status"
                                source="offer_status"
                                defaultValue="offer_status"
                            />
                            <TextInput
                                label="Online listing"
                                source="online_listing"
                                defaultValue="online_listing"
                            />
                            <TextInput
                                label="Outdoor space types"
                                source="outdoor_space_types"
                                defaultValue="outdoor_space_types"
                            />
                            <TextInput
                                label="Postcode"
                                source="postcode"
                                defaultValue="postcode"
                            />
                            <TextInput
                                label="Property type"
                                source="property_type"
                                defaultValue="property_type"
                            />
                            <SelectInput
                                label="Property viewed by customer"
                                source="property_viewed_by_customer"
                                defaultValue="property_viewed_by_customer"
                                choices={setTrueOrFalse(trueOrFalse)}
                            />
                            <TextInput
                                label="Remaining years on lease"
                                source="remaining_years_on_lease"
                                defaultValue="remaining_years_on_lease"
                            />
                            <NumberInput
                                label="Service charge"
                                source="service_charge"
                                defaultValue="service_charge"
                            />
                            <SelectInput
                                source="epc_rating"
                                choices={[
                                    { id: "A", name: "A" },
                                    { id: "B", name: "B" },
                                    { id: "C", name: "C" },
                                    { id: "D", name: "D" },
                                    { id: "F", name: "F+" },
                                ]}
                            />
                            <SelectInput
                                label="Initiation"
                                source="initiation_status"
                                choices={[
                                    { id: "NOT_STARTED", name: "NOT_STARTED" },
                                    {
                                        id: "AML_SENT",
                                        name: "AML_SENT",
                                    },
                                    {
                                        id: "MOS_RECEIVED",
                                        name: "MOS_RECEIVED",
                                    },
                                    {
                                        id: "CONTRACT_RECEIVED",
                                        name: "CONTRACT_RECEIVED",
                                    },
                                    {
                                        id: "SOLICITORS_INSTRUCTED",
                                        name: "SOLICITORS_INSTRUCTED",
                                    },
                                    { id: "OVERDUE", name: "OVERDUE" },
                                ]}
                            />
                            <SelectInput
                                label="RICS building survey & valuation"
                                source="survey_status"
                                choices={[
                                    { id: "NOT_STARTED", name: "NOT_STARTED" },
                                    {
                                        id: "CUST_PAID_OR_NA",
                                        name: "CUST_PAID_OR_NA",
                                    },
                                    { id: "INSTRUCTED", name: "INSTRUCTED" },
                                    { id: "BOOKED", name: "BOOKED" },
                                    { id: "ATTENDED", name: "ATTENDED" },
                                    { id: "RECEIVED", name: "RECEIVED" },
                                    { id: "APPROVED", name: "APPROVED" },
                                    { id: "OVERDUE", name: "OVERDUE" },
                                    { id: "DECLINED", name: "DECLINED" },
                                ]}
                            />
                            <SelectInput
                                label="Specialist reports"
                                source="specialist_survey_status"
                                choices={[
                                    { id: "NOT_STARTED", name: "NOT_STARTED" },
                                    {
                                        id: "INSTRUCTED",
                                        name: "INSTRUCTED",
                                    },
                                    { id: "BOOKED", name: "BOOKED" },
                                    { id: "ATTENDED", name: "ATTENDED" },
                                    { id: "RECEIVED", name: "RECEIVED" },
                                    { id: "APPROVED", name: "APPROVED" },
                                    { id: "OVERDUE", name: "OVERDUE" },
                                    { id: "DECLINED", name: "DECLINED" },
                                    { id: "ISSUES", name: "ISSUES" },
                                ]}
                            />
                            <SelectInput
                                label="Legal review"
                                source="legal_status"
                                choices={[
                                    { id: "NOT_STARTED", name: "NOT_STARTED" },
                                    {
                                        id: "SEARCHES_STARTED",
                                        name: "SEARCHES_STARTED",
                                    },
                                    {
                                        id: "SEARCHES_COMPLETE",
                                        name: "SEARCHES_COMPLETE",
                                    },
                                    {
                                        id: "FINAL_LEGAL_QUERIES",
                                        name: "FINAL_LEGAL_QUERIES",
                                    },
                                    {
                                        id: "LR_FINALISED_AND_UNDER_REVIEW",
                                        name: "LR_FINALISED_AND_UNDER_REVIEW",
                                    },
                                    { id: "LR_APPROVED", name: "LR_APPROVED" },
                                    { id: "OVERDUE", name: "OVERDUE" },
                                    { id: "LR_REJECTED", name: "LR_REJECTED" },
                                ]}
                            />
                            <SelectInput
                                label="Exchange & completion"
                                source="exchange_status"
                                choices={[
                                    { id: "NOT_STARTED", name: "NOT_STARTED" },
                                    {
                                        id: "EXCHANGE_DATE_AGREED",
                                        name: "EXCHANGE_DATE_AGREED",
                                    },
                                    {
                                        id: "EXCHANGED",
                                        name: "EXCHANGED",
                                    },
                                    {
                                        id: "COMPLETE",
                                        name: "COMPLETE",
                                    },
                                    { id: "OVERDUE", name: "OVERDUE" },
                                ]}
                            />
                            <SelectInput
                                label="AST"
                                source="ast_status"
                                choices={[
                                    { id: "NOT_STARTED", name: "NOT_STARTED" },
                                    {
                                        id: "CONTRACT_PREPARED",
                                        name: "CONTRACT_PREPARED",
                                    },
                                    { id: "EXCHANGED", name: "EXCHANGED" },
                                    {
                                        id: "SENT_TO_CUST",
                                        name: "SENT_TO_CUST",
                                    },
                                    { id: "SIGNED", name: "SIGNED" },
                                    { id: "OVERDUE", name: "OVERDUE" },
                                ]}
                            />
                            <SelectInput
                                label="Rent and Fee"
                                source="rent_and_fee_status"
                                choices={[
                                    { id: "NOT_STARTED", name: "NOT_STARTED" },
                                    { id: "CREATED_INV", name: "CREATED_INV" },
                                    { id: "SENT_INV", name: "SENT_INV" },
                                    { id: "PAID", name: "PAID" },
                                    { id: "OVERDUE ", name: "OVERDUE " },
                                ]}
                            />
                            <TextInput label="Latitude" source="latitude" />
                            <TextInput label="Longitude" source="longitude" />
                        </FormTab>
                    </TabbedForm>
                </Edit>
            ) : (
                <Show {...props} actions={<ShowButtonAction />}>
                    <TabbedShowLayout>
                        <Tab label="Property Details">
                            <ApplicationShowLink source="application_id" />
                            <TextField source="id"></TextField>
                            <TextField source="created_at"></TextField>
                            <TextField source="updated_at"></TextField>
                            <TextField source="active"></TextField>
                            <TextField source="status"></TextField>
                            <TextField source="address"></TextField>
                            <TextField source="agent_company_name"></TextField>
                            <TextField source="agent_email"></TextField>
                            <TextField source="agent_name"></TextField>
                            <TextField source="agent_phone_number"></TextField>
                            <TextField source="asking_price"></TextField>
                            <TextField source="building_type"></TextField>
                            <TextField source="city_or_town"></TextField>
                            <TextField source="floor_number"></TextField>
                            <TextField source="ground_rent"></TextField>
                            <TextField source="has_outdoor_space"></TextField>
                            <TextField source="has_parking_or_garage"></TextField>
                            <TextField source="house_number"></TextField>
                            <TextField source="house_type"></TextField>
                            <TextField source="internal_area"></TextField>
                            <TextField source="lease_type"></TextField>
                            <TextField source="number_of_bathrooms"></TextField>
                            <TextField source="number_of_bedrooms"></TextField>
                            <TextField source="number_of_floors"></TextField>
                            <TextField source="number_of_other_rooms"></TextField>
                            <TextField source="offer_accepted_at"></TextField>
                            <TextField source="offer_amount"></TextField>
                            <TextField source="offer_made"></TextField>
                            <TextField source="offer_status"></TextField>
                            <TextField source="online_listing"></TextField>
                            <TextField source="outdoor_space_types"></TextField>
                            <TextField source="postcode"></TextField>
                            <TextField source="property_type"></TextField>
                            <TextField source="property_viewed_by_customer"></TextField>
                            <TextField source="remaining_years_on_lease"></TextField>
                            <TextField source="service_charge"></TextField>
                            <TextField source="using_estate_agent"></TextField>
                            <TextField source="epc_rating"></TextField>
                            <TextField
                                source="initiation_status"
                                label="Initiation"
                            ></TextField>
                            <TextField
                                source="survey_status"
                                label="RICS building survey & valuation"
                            ></TextField>
                            <TextField
                                source="specialist_survey_status"
                                label="Specialist reports"
                            ></TextField>
                            <TextField
                                source="legal_status"
                                label="Legal review"
                            ></TextField>
                            <TextField
                                label="Exchange & completion"
                                source="exchange_status"
                            ></TextField>
                            <TextField
                                source="ast_status"
                                label="AST"
                            ></TextField>
                            <TextField
                                source="rent_and_fee_status"
                                label="Rent and Fee"
                            ></TextField>
                            <TextField
                                source="latitude"
                                label="Latitude"
                            ></TextField>
                            <TextField
                                source="longitude"
                                label="Longitude"
                            ></TextField>
                        </Tab>
                    </TabbedShowLayout>
                </Show>
            )}
            <ApproveProperty {...props} isOpen={approvePopupOpened} />
            <RejectProperty {...props} isOpen={rejectPopupOpened} />
        </>
    );
};
