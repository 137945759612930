import { Button } from "@material-ui/core";
import { List, TextField, Datagrid, FunctionField } from "react-admin";
import { downloadDocument } from "./utils/download-document";

// TODO: Implement filters
// const postFilters = [
//     <SearchInput source="id" alwaysOn />,
//     <SearchInput source="name" placeholder="Name" />,
// ];

export default (props) => {
    return (
        <>
            <List {...props} sort={{ field: "updated_at", order: "DESC" }}>
                <Datagrid rowClick="show">
                    <TextField source="id" />
                    <TextField label="File name" source="name" />
                    <TextField label="Description" source="description" />
                    <TextField label="Box id" source="box_file_id" />
                    <FunctionField
                        render={(record) => (
                            <Button
                                color="secondary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    downloadDocument(record?.id.toString());
                                }}
                            >
                                Download
                            </Button>
                        )}
                        label=""
                    />
                </Datagrid>
            </List>
        </>
    );
};
