import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Modal,
    TextField,
} from "@material-ui/core";
import { AxiosError } from "axios";
import { useState } from "react";
import { create_budget_in_principle } from "../../../api-requests/budget-in-principle-create";
import { update_budget_in_principle } from "../../../api-requests/budget-in-principle-update";

export default ({ record }) => {
    let [open, toggleModal] = useState(false);

    let [formData, updateFormData] = useState({
        amount: record?.budget_in_principle?.amount ?? "",
    });

    let valueFormatter = {
        amount: (val) => parseFloat(val),
    };

    function _toggle() {
        record?.budget_in_principle &&
            updateFormData({
                amount: record?.budget_in_principle?.amount ?? "",
            });

        toggleModal(!open);
    }

    async function createOrUpdateBudget() {
        try {
            if (Boolean(record?.budget_in_principle)) {
                await update_budget_in_principle(record.id, formData);
                return;
            }

            if (
                window.confirm(
                    `This will allow this Customer to access their Application Dashboard, Are you sure?`,
                )
            ) {
                await create_budget_in_principle(record?.id, formData);
            }
        } catch (err: any) {
            let message: string = `${err.message}\n`;

            let errors = (err as AxiosError).response?.data?.context?.errors;

            if (errors) {
                message = message.concat((errors as string[]).join("\n"));
                return alert(message);
            }
        } finally {
            toggleModal(false);
        }
    }

    function handleFormChange(event) {
        let { name, value } = event.target;

        updateFormData({
            ...formData,
            [name]: valueFormatter[name](value),
        });
    }

    return (
        <>
            <Modal
                open={open}
                onClose={(e) => toggleModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{}}
                >
                    <Card className="p-2">
                        <CardHeader
                            title={
                                record?.budget_in_principle
                                    ? "Update Budget"
                                    : "Create Budget"
                            }
                        ></CardHeader>
                        <CardContent>
                            <TextField
                                required
                                fullWidth
                                label="Amount"
                                type="number"
                                name="amount"
                                value={formData.amount}
                                onChange={handleFormChange}
                            />
                        </CardContent>
                        <CardActions>
                            <Button
                                color="primary"
                                onClick={createOrUpdateBudget}
                            >
                                Submit
                            </Button>
                        </CardActions>
                    </Card>
                </Box>
            </Modal>

            <Button color="primary" onClick={_toggle}>
                {record?.budget_in_principle ? "Update" : "Create"} Budget in
                Principle
            </Button>
        </>
    );
};
