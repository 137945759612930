import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Modal,
    TextField as TextFieldModal,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { entity_create } from "../../api-requests/entity-create";
import { AxiosError } from "axios";

export default (props) => {
    let [open, toggleModal] = useState(false);

    useEffect(() => {
        toggleModal(props.isOpen);
    }, [props.isOpen]);

    let [formData, updateFormData] = useState({
        name: "",
        fca_number: "",
        contact_name: "",
        contact_email: "",
        contact_number: "",
        postcode: "",
        commission_rate: "",
        agreement_signed: false,
    });

    let valueFormatter = {
        name: (val: string) => val,
        fca_number: (val: string) => val,
        contact_name: (val: string) => val,
        contact_email: (val: string) => val,
        contact_number: (val: string) => val,
        postcode: (val: string) => val,
        commission_rate: (val: string) => parseFloat(val).toString(),
    };

    function handleFormChange(event) {
        let { name, value } = event.target;
        updateFormData({
            ...formData,
            [name]: valueFormatter[name](value),
        });
    }
    async function createEntity() {
        try {
            await entity_create(formData);
        } catch (err: any) {
            let message: string = `${err.message}\n`;

            let errors = (err as AxiosError).response?.data?.context?.errors;

            if (errors) {
                message = message.concat((errors as string[]).join("\n"));
                return alert(message);
            }
        } finally {
            toggleModal(false);
        }
    }

    return (
        <>
            <Modal
                open={open}
                onClose={(e) => {
                    toggleModal(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    display="block"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ paddingTop: "50px;", margin: "0 auto", width: "50%" }}
                >
                    <Card className="p-2">
                        <CardHeader title="Create Entity"></CardHeader>
                        <CardContent>
                            <TextFieldModal
                                label="Entity name"
                                name="name"
                                required
                                fullWidth
                                onChange={handleFormChange}
                            />
                            <TextFieldModal
                                label="FCA number"
                                name="fca_number"
                                required
                                fullWidth
                                type="number"
                                onChange={handleFormChange}
                            />
                            <TextFieldModal
                                label="Contact name"
                                name="contact_name"
                                required
                                fullWidth
                                onChange={handleFormChange}
                            />
                            <TextFieldModal
                                label="Contact email"
                                name="contact_email"
                                required
                                fullWidth
                                onChange={handleFormChange}
                            />
                            <TextFieldModal
                                label="Contact number"
                                name="contact_number"
                                required
                                fullWidth
                                type="number"
                                onChange={handleFormChange}
                            />
                            <TextFieldModal
                                label="Post code"
                                name="postcode"
                                required
                                fullWidth
                                onChange={handleFormChange}
                            />
                            <TextFieldModal
                                label="Commission rate"
                                name="commission_rate"
                                required
                                fullWidth
                                type="number"
                                onChange={handleFormChange}
                            />
                        </CardContent>
                        <CardActions>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                    createEntity();
                                }}
                            >
                                Submit
                            </Button>
                        </CardActions>
                    </Card>
                </Box>
            </Modal>
        </>
    );
};
