import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Modal,
    TextField,
} from "@material-ui/core";
import { useState } from "react";
import fileDownload from "js-file-download";
import { create_standalone_budget_pdf } from "../../api-requests/budget-in-principle-standalone-pdf";
import { budgetInPrincipleName } from "../../utils/bip-name-generator";

export default () => {
    let [open, toggleModal] = useState(false);

    let [formData, updateFormData] = useState({
        applicant1: "",
        applicant2: "",
        amount: "",
        reference: "",
        referrer: "",
        referrerCompany: "",
    });

    let valueFormatter = {
        reference: (val: string) => val.toUpperCase(),
    };

    async function performAction() {
        try {
            let applicants = [formData.applicant1, formData.applicant2].filter(
                Boolean,
            );

            let payload = {
                applicants,
                amount: formData.amount || undefined,
                reference: formData.reference || undefined,
                referrer: formData.referrer || undefined,
                referrerCompany: formData.referrerCompany || undefined,
            };

            let response = await create_standalone_budget_pdf(payload);

            let fileName = budgetInPrincipleName(applicants.join(", "));

            fileDownload(response.data, fileName, "application/pdf");
        } catch (err: any) {
            console.error(err);
        } finally {
            toggleModal(false);
        }
    }

    function handleFormChange(event) {
        let { name, value } = event.target;

        updateFormData({
            ...formData,
            [name]: valueFormatter[name]?.(value) ?? value,
        });
    }

    return (
        <>
            <Modal
                open={open}
                onClose={(e) => toggleModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{}}
                >
                    <Card className="p-2">
                        <CardHeader title="Stand Alone Budget in Principle"></CardHeader>
                        <CardContent>
                            <TextField
                                required
                                fullWidth
                                label="Main Applicant"
                                name="applicant1"
                                value={formData.applicant1}
                                onChange={handleFormChange}
                            />

                            <TextField
                                fullWidth
                                label="Secondary Applicant"
                                name="applicant2"
                                value={formData.applicant2}
                                onChange={handleFormChange}
                            />

                            <TextField
                                required
                                fullWidth
                                label="Amount"
                                type="number"
                                name="amount"
                                value={formData.amount}
                                onChange={handleFormChange}
                            />

                            <TextField
                                required
                                fullWidth
                                label="Reference"
                                name="reference"
                                value={formData.reference}
                                onChange={handleFormChange}
                            />

                            <TextField
                                fullWidth
                                label="Referrer"
                                name="referrer"
                                value={formData.referrer}
                                onChange={handleFormChange}
                            />

                            <TextField
                                fullWidth
                                label="Referrer Company"
                                name="referrerCompany"
                                value={formData.referrerCompany}
                                onChange={handleFormChange}
                            />
                        </CardContent>
                        <CardActions>
                            <Button color="primary" onClick={performAction}>
                                Download PDF
                            </Button>
                        </CardActions>
                    </Card>
                </Box>
            </Modal>

            <Button color="primary" onClick={() => toggleModal(!open)}>
                Generate Stand-alone Budget PDF
            </Button>
        </>
    );
};
