import create from "zustand";
import { $axios } from "../utils/axios";

import { AUTH_TOKEN } from "../react-admin/auth-provider";

export const SettingsStore = create((set: Function) => {
    let _state = {
        settings: [],
    };

    return {
        get settings() {
            return _state.settings;
        },

        async fetchSettings() {
            let res = await $axios({
                url: "/admin/calculator-settings",
                method: "get",
                headers: {
                    authorization: localStorage.getItem(AUTH_TOKEN) ?? "",
                },
            });

            set(() => {
                _state.settings = res.data;
            });
        },
    };
});
