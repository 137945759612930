import { Button } from "@material-ui/core";
import { AxiosError } from "axios";
import fileDownload from "js-file-download";
import { TopToolbar } from "react-admin";
import { preview_budget_in_principle } from "../../../api-requests/budget-in-principle-download";
import { budgetInPrincipleName } from "../../../utils/bip-name-generator";

export default ({ record }) => {
    const downloadPreview = async () => {
        const token = localStorage.getItem("token");

        window.open(
            `${process.env.REACT_APP_CUSTOMER_APP}/api/pdf?token=${token}&applicationId=${record?.id}&pdfType=bip-pdf`,
            "_blank",
            "noreferrer",
        );
    };

    return (
        <Button color="primary" onClick={downloadPreview}>
            Preview Budget in Principle
        </Button>
    );
};
