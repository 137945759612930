import {
    TextField,
    DateField,
    Edit,
    SimpleForm,
    TextInput,
    Toolbar,
    SaveButton,
    SelectInput,
    regex,
} from "react-admin";
import { useEffect, useState } from "react";
import { EntityStore } from "../../../store/entities";

const UserEditToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);
export const BROKER_TYPES = [
    { id: "DIRECTLY_AUTHORISED", name: "Direct" },
    { id: "APPOINTED_REPRESENTATIVE", name: "Appointed" },
    { id: "OTHER", name: "Neither" },
];
export const BROKER_PROFESSIONS = [
    { id: "MORTGAGE_BROKER", name: "Mortgage Broker/Advisor" },
    { id: "FINANCIAL_ADVISOR", name: "Financial Advisor" },
    { id: "ESTATE_AGENT", name: "Estate Agent" },
    { id: "OTHER", name: "Other" },
];
export default (props) => {
    let _EntityStore = EntityStore();
    const [entities, setEntities] = useState({} as any);

    useEffect(() => {
        if (_EntityStore.entities)
            _EntityStore
                .fetchEntities()
                .then((res) => setEntities(_EntityStore.entities));
    }, []);

    const validateMaskSpaces = regex(/^\S+$/, "No spaces are allowed");
    const transform = (data) => {
        const {
            first_name,
            last_name,
            contact_number,
            company_name,
            fca_number,
            network_name,
            type,
            entity_id,
            referrer_mask,
            profession,
        } = data;

        return {
            first_name,
            last_name,
            contact_number,
            company_name,
            fca_number,
            network_name,
            type,
            entity_id,
            referrer_mask,
            profession,
        };
    };
    const entitiesOption = !!entities.result
        ? entities.result.map((item) => {
              return { id: item.id, name: item.name };
          })
        : [];

    return (
        <Edit {...props} mutationMode="optimistic" transform={transform}>
            <SimpleForm rowClick="show" toolbar={<UserEditToolbar />}>
                <TextField source="id" />
                <TextField label="Email" source="email" />
                <TextInput label="First Name" source="first_name" />
                <TextInput label="Last Name" source="last_name" />
                <TextInput label="Company Name" source="company_name" />
                <TextInput
                    label="Referrer Mask"
                    source="referrer_mask"
                    validate={validateMaskSpaces}
                />
                <TextInput label="Contact Number" source="contact_number" />
                <TextInput label="Fca Number" source="fca_number" />
                <TextInput label="Network Name" source="network_name" />
                <SelectInput source="type" choices={BROKER_TYPES} />
                <SelectInput source="profession" choices={BROKER_PROFESSIONS} />
                <SelectInput source="entity_id" choices={entitiesOption} />
                {/* <TextInput source="type" /> */}
                <DateField label="Created" source="created_at" showTime />
                <DateField label="Last Updated" source="updated_at" showTime />
                <TextField source="status" />
            </SimpleForm>
        </Edit>
    );
};
