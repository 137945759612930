import {
    DeleteManyParams,
    DeleteParams,
    GetListParams,
    GetManyParams,
    UpdateParams,
    CreateParams,
} from "react-admin";
import { $axios } from "../utils/axios";
import { AUTH_TOKEN } from "./auth-provider";

async function _update(resource, { id, data }: UpdateParams) {
    if (resource === "applicants") {
        return {
            id: id,
            data: data,
        };
    }
    let res = await $axios({
        method: "put",
        url: `/${resource}/${id}`,
        data,
        headers: {
            authorization: localStorage.getItem(AUTH_TOKEN) ?? "",
        },
    });

    return {
        id: id,
        data: res.data ? res.data : data,
    };
}

async function _find(resource, { pagination, sort, filter }: GetListParams) {
    const calculateOffset = (pagination: GetListParams["pagination"]) => {
        return pagination.page === 1
            ? undefined
            : (pagination.page - 1) * pagination.perPage || undefined;
    };

    const filterNoHash =
        resource === "properties"
            ? { application_id: filter?.application_id?.replace("#", "") }
            : { id: filter?.id?.replace("#", "") };

    let res = await $axios({
        method: "get",
        url: `/${resource}`,
        params: {
            limit: pagination.perPage,
            offset: calculateOffset(pagination),
            sortBy: sort.field,
            sortByOrder: sort.order.toLowerCase(),
            where: filterNoHash,
            search: filter?.name?.replace("#", ""),
            filter: filter,
        },
        headers: {
            authorization: localStorage.getItem(AUTH_TOKEN) ?? "",
        },
    });

    return {
        total: res.data.total,
        data: res.data.result,
    };
}

async function _fetch(resource, { id }) {
    let res = await $axios.get(`/${resource}/${id}`, {
        headers: {
            authorization: localStorage.getItem(AUTH_TOKEN) ?? "",
        },
    });

    if (resource === "properties" && res) {
        let resForApplications = await $axios.get(
            `/applications/${res.data.application_id}`,
            {
                headers: {
                    authorization: localStorage.getItem(AUTH_TOKEN) ?? "",
                },
            },
        );
        res.data = {
            ...res.data,
            offer: resForApplications.data.offer,
        };
    }

    return res.data;
}

async function _delete(resource, id) {
    return $axios({
        method: "delete",
        url: `/${resource}/${id}`,
        headers: {
            authorization: localStorage.getItem(AUTH_TOKEN) ?? "",
        },
    });
}

async function _create(resource, { data }: CreateParams) {
    return $axios({
        method: "post",
        url: `/${resource}/`,
        data,
        headers: {
            authorization: localStorage.getItem(AUTH_TOKEN) ?? "",
        },
    }).catch((e) => {
        throw e.response;
    });
}

export default {
    getList: async (resource, params: GetListParams): Promise<any> => {
        return _find(resource, params);
    },

    getOne: async (resource, params): Promise<any> => {
        return {
            data: await _fetch(resource, params),
        };
    },

    getMany: async (resource, params: GetManyParams): Promise<any> => {
        return {
            data: await Promise.all(
                params.ids.map((id) => _fetch(resource, { id })),
            ),
        };
    },

    deleteMany: async (resource, { ids }: DeleteManyParams): Promise<any> => {
        return {
            data: await Promise.all(ids.map((id) => _delete(resource, id))),
        };
    },

    update: async (resource, params): Promise<any> => {
        const res = await _update(resource, params);

        return {
            data: res.data,
        };
    },

    create: async (resource, params): Promise<any> => {
        const res = await _create(resource, params);

        return {
            data: res.data,
        };
    },

    // TODO

    getManyReference: async (resource, params): Promise<any> => {
        console.log({
            resource,
            name: "getManyReference",
            params,
        });
    },

    updateMany: async (resource, params): Promise<any> => {
        console.log({
            name: "updateMany",
            resource,
            params,
        });
    },

    delete: async (resource, params: DeleteParams): Promise<any> => {
        const res = await _delete(resource, params.id);

        return {
            data: res.data,
        };
    },
};
