import { AUTH_LOGIN, AUTH_CHECK, AUTH_LOGOUT } from "react-admin";
import { $axios } from "../utils/axios";

export const AUTH_TOKEN = "token";

export default async (type, params) => {
    switch (type) {
        case AUTH_LOGIN:
            return login(params);
        case AUTH_CHECK:
            return checkAuth();
        case AUTH_LOGOUT:
            return logOut();
        default:
            console.log({ msg: "unmapped auth provider request", type });
            return;
    }
};

async function logOut() {
    localStorage.removeItem(AUTH_TOKEN);
}

async function login(params) {
    let { username: email, password } = params;

    let res = await $axios({
        url: `/auth/login`,
        method: "post",
        data: {
            email,
            password,
        },
    });

    localStorage.setItem(AUTH_TOKEN, res.data);
}

async function checkAuth() {
    let token = localStorage.getItem(AUTH_TOKEN) ?? "";

    return $axios({
        url: `/auth/verify`,
        method: "post",
        headers: {
            Authorization: token,
        },
    });
}
