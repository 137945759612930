import { AUTH_TOKEN } from "../react-admin/auth-provider";
import { $axios } from "../utils/axios";

export async function broker_update(
    brokerId: string,
    payload: Record<string, any>,
) {
    return $axios.put(`/brokers/${brokerId}`, payload, {
        headers: {
            authorization: localStorage.getItem(AUTH_TOKEN) ?? "",
        },
    });
}
export async function broker_approve(brokerId: string) {
    return $axios.post(
        `/brokers/${brokerId}/approve`,
        {},
        {
            headers: {
                authorization: localStorage.getItem(AUTH_TOKEN) ?? "",
            },
        },
    );
}
export async function broker_reject(brokerId: string) {
    return $axios.post(
        `/brokers/${brokerId}/reject`,
        {},
        {
            headers: {
                authorization: localStorage.getItem(AUTH_TOKEN) ?? "",
            },
        },
    );
}
