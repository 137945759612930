import create, { StateCreator, StoreApi, SetState } from "zustand";
import { $axios } from "../utils/axios";

import { AUTH_TOKEN } from "../react-admin/auth-provider";

export const EntityStore = create((set: (operator: () => void) => void) => {
    let _state = {
        entities: [],
    };

    return {
        get entities() {
            return _state.entities;
        },

        async fetchEntities() {
            let res = await $axios({
                url: "/entities",
                method: "get",
                headers: {
                    authorization: localStorage.getItem(AUTH_TOKEN) ?? "",
                },
            });

            set(() => {
                _state.entities = res.data;
            });
        },
    };
});
