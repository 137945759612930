import StandaloneBudgetPdf from "./standalone-budget-pdf";
import Settings from "./settings";

export default () => {
    return (
        <>
            <h1>Keyzy Admin Panel</h1>

            <div className="grid grid-cols-3 p-4">
                <div>
                    <StandaloneBudgetPdf />
                </div>
                <div>
                    <Settings />
                </div>
            </div>
        </>
    );
};
