import { Button } from "@material-ui/core";
import { AUTH_TOKEN } from "../../../react-admin/auth-provider";
import { $axios } from "../../../utils/axios";
import { budgetInPrincipleName } from "../../../utils/bip-name-generator";
import fileDownload from "js-file-download";

export default ({ record }) => {
    let _download = async () => {
        let response = await $axios({
            method: "get",
            url: `/admin/${record?.id}/xlsx`,
            headers: {
                authorization: localStorage.getItem(AUTH_TOKEN) ?? "",
            },
            responseType: "arraybuffer",
        });

        let fileName = `${
            record?.applicants
                .map((item) => item.full_name)
                ?.join(", ")
                ?.trim() ?? record.id
        } - Underwriting.xlsx`;

        fileDownload(response.data, fileName, response.headers["content-type"]);
    };

    return (
        <>
            <Button color="primary" onClick={_download}>
                Download Underwriting File
            </Button>
        </>
    );
};
