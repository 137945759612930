import { ReactElement } from "react";
import { BulkActionProps, Button, Identifier, useNotify } from "react-admin";
import { $axios } from "../../../../utils/axios";
import { AUTH_TOKEN } from "../../../../react-admin/auth-provider";

export interface BulkActionButtonProps extends BulkActionProps {
    applicationId: string;
    onComplete: () => void;
}
export const BulkActionAppUnLinkDocumentsButton = ({
    applicationId,
    selectedIds,
    onComplete,
}: BulkActionButtonProps): ReactElement | null => {
    const notify = useNotify();
    const unLinkDocuments = async () => {
        if (!selectedIds) return;
        for (let i = 0; i < selectedIds?.length; i++) {
            const documentId = selectedIds[i];
            await unlink(documentId.toString(), applicationId);
        }
        notify("Unlinked document(s)");
        onComplete();
    };
    const unlink = async (documentId: string, applicationId: string) => {
        await $axios.post(
            `/documents/${documentId}/unlink`,
            { application_id: applicationId },
            {
                headers: {
                    authorization: localStorage.getItem(AUTH_TOKEN) ?? "",
                },
            },
        );
    };
    return (
        <Button
            color="secondary"
            onClick={() => {
                unLinkDocuments();
            }}
            label="unlink"
        />
    );
};
