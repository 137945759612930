import {
    Edit,
    SimpleForm,
    TextInput,
    required,
    TextField,
    DateField,
} from "react-admin";

export const DocumentEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextField label="Id" source="id" />
            <DateField source="created_at" label="Created At" showTime />
            <TextInput source="name" validate={[required()]} />
            <TextInput source="description" validate={[required()]} fullWidth />
            <TextInput source="box_file_id" validate={[required()]} />
        </SimpleForm>
    </Edit>
);
