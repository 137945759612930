import { AUTH_TOKEN } from "../react-admin/auth-provider";
import { $axios } from "../utils/axios";

export async function approve_property(propertyId: string, payload: any) {
    return $axios.post(`/properties/${propertyId}/approve`, payload, {
        headers: {
            authorization: localStorage.getItem(AUTH_TOKEN) ?? "",
        },
    });
}
